import React, { Component } from 'react';
import { connect } from 'react-redux';

class Pages extends Component {
	createMarkupPage(pageContent) {
		return { __html: pageContent };
	}

	render() {
		let slug = this.props.match.params.slug;
		let page = this.props.wp.pages.find(function(item) {
			return item.post_name === slug;
		});

		console.log('page', page);

		return (
			<div className="content">
				{page !== undefined && (
					<div>
						<h3>{page.post_title}</h3>
						<div dangerouslySetInnerHTML={this.createMarkupPage(page.post_content)} />
					</div>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		settings: state.settings,
		wp: state.wp
	};
}

export default connect(mapStateToProps)(Pages);