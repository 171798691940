import { combineReducers } from 'redux';

import { localizeReducer } from 'react-localize-redux';
import settings from './settings';
import { wp } from './wp';
import { wccd } from './wccd';
import user from './user';
// import { compareOne, compareTwo, currentScenario, availability} from './scenario-reducer'

const allReducers = combineReducers({
	settings,
	user,
	wp,
	wccd,
	localize: localizeReducer
	// compareTwo,
	// currentScenario,
	// availability
});

// fieldValues: fieldValues,

export default allReducers;
