import * as actions from '../actions/action-types';
//import config from '../config/config';

export function wp(
	state = {
		loading: false,
		posts: [],
		pages: [],
		lastUpdate: 0,
		error: null
	},
	action
) {
	switch (action.type) {
		case actions.LOADING_POSTS:
			console.log('laddar poster', action.payload);
			return {
				...state,
				loading: true
			};

		case actions.SAVE_ALL_POSTS:
			//console.log('sparar alla poster', action.payload);
			return {
				...state,
				posts: action.payload.allPosts
			};
		//lastUpdate: action.payload.lastDownloadTime
		// return state;

		case actions.SAVE_ALL_PAGES:
			//console.log('sparar alla poster', action.payload);
			return {
				...state,
				pages: action.payload.allPages
			};
		//lastUpdate: action.payload.lastDownloadTime
		// return state;

		default:
			return state;
	}
}
