import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import axios from 'axios';
import { Segment, Button, Icon, Step, Message } from 'semantic-ui-react';

import config from '../config/config';
import { emptyCart, saveOrderForPrint } from '../actions/wccd-content';
import { doStep } from '../actions/settings-action';
import { emptyCheckOutUser, updateUserPhone } from '../actions/user-action';

import Cart from '../components/cart';
import CheckoutUser from '../components/checkout-user';
import Login from '../components/login';
import Summary from '../components/checkout-summary';

import checkUser from '../functions/check-user';

class Checkout extends Component {
	constructor(props) {
		super(props);
		let step = this.props.match.params.step ? parseInt(this.props.match.params.step) : 1;
		props.doStep(step);

		this.state = {
			step: step,
			stepComplete: [],
			doSummery: false,
			sendButtonLoading: false
		};
	}

	componentDidMount() {
		let userExist = checkUser(this.props.user);
		if (!userExist) {
			this.props.history.replace('/');
		}
	}

	componentDidUpdate() {
		if (this.state.step !== this.props.settings.step) {
			this.setState({ step: this.props.settings.step });
		}
	}

	/** Private functions */

	_checkLastNextStepComplete() {
		const { step } = this.state;
		if (step === 2) {
			let user = this.props.user.checkOutUser;
			if (
				user.firstname !== '' &&
				user.lastname !== '' &&
				user.customerOrderNum !== '' &&
				user.backorder_split_delivery !== 0 &&
				user.delivery !== {} &&
				user.choosenDeliveryAddressId >= 0
			) {
				return false;
			} else {
				return true;
			}
		}
	}

	_nextStep = () => {
		const { step } = this.state;
		if (step === 2) {
			let user = this.props.user.checkOutUser;
			if (
				user.firstname !== '' &&
				user.lastname !== '' &&
				user.customerOrderNum !== '' &&
				user.backorder_split_delivery !== 0 &&
				user.delivery !== {}
			) {
				this.setState({ step: step + 1, doSummery: false });
				this.props.doStep(step + 1);
				this.props.history.push('/checkout/' + (step + 1));
			} else {
				this.setState({ doSummery: true });
			}
		} else {
			this.setState({ step: step + 1, doSummery: false });
			this.props.doStep(step + 1);
			this.props.history.push('/checkout/' + (step + 1));
		}
	};

	_prevStep = () => {
		const { step } = this.state;
		this.setState({ step: step - 1 });
		this.props.doStep(step - 1);
		this.props.history.push('/checkout/' + (step - 1));
	};

	_emptyCart = () => {
		let confirmEmpty = window.confirm('Är du säker på att du tömma kundvagnen?');
		if (confirmEmpty === true) {
			this.props.emptyCart();
		}
	};

	_sendCart = () => {
		this.setState({ sendButtonLoading: true });
		const user = this.props.user.checkOutUser;
		let shippingAddress2 = '';
		const cartItems = this.props.cart.map((item) => {
			return {
				product_id: item.productId,
				quantity: item.numOfProducts
			};
		});
		if (user.delivery.street2 !== undefined) {
			shippingAddress2 = user.delivery.street2;
		}
		const backorder_split_delivery = user.backorder_split_delivery === 1 ? true : false;
		let orderData = {
			payment_method: 'bacs',
			payment_method_title: 'Direct Bank Transfer',
			set_paid: false,
			shipping: {
				first_name: user.delivery.attn_first_name,
				last_name: user.delivery.attn_last_name,
				address_1: user.delivery.street,
				address_2: shippingAddress2,
				city: user.delivery.postal_area,
				state: '',
				postcode: user.delivery.zip_code,
				country: user.delivery.country,
				delivery_address_id: user.delivery.id
			},
			line_items: cartItems,
			shipping_lines: [
				{
					method_id: 'flat_rate',
					method_title: 'Flat Rate',
					total: 0
				}
			],
			_ref_email: user.email,
			_ref_first_name: user.firstname,
			_ref_last_name: user.lastname,
			_ref_phone: user.phone,
			_main_customer_id: user.mainCustomerId,
			_discount: user.discount,
			_backorder_split_delivery: backorder_split_delivery,
			_customer_order_num: user.customerOrderNum,
			_parcel_id: user.parcelId,
			_notes: user.notes,
			gId: user.gId,
			user_phone: user.phone
		};

		axios
			.post(config.API_ENDPOINTS.POST_ORDER, orderData, config.xhrConfig)
			.then((res) => {
				if (res.data.success === true) {
					this.props.saveOrderForPrint(orderData);
					this.props.emptyCart();
					this.props.emptyCheckOutUser();
					this.props.updateUserPhone(user.phone);
					this.props.doStep(1);
					this.props.history.push('/order-sent');
				}
			})
			.catch((error) => {
				console.log('error send order', error);
			});
	};

	_getSegment(step) {
		let userExist = checkUser(this.props.user);
		let checkOutUser = this.props.user.checkOutUser;
		let nextStepDeactivated = true;
		let nextStepDeactivatedEmptyCart = true;
		if (this.props.cart.length > 0) {
			nextStepDeactivatedEmptyCart = false;
		}
		if (Object.keys(checkOutUser).length > 0) {
			nextStepDeactivated = this._checkLastNextStepComplete();
		}
		switch (step) {
			case 1:
				return (
					<Segment attached style={{overflowX:"scroll"}}>
						<h2>
							<Translate id="cart" />
						</h2>
						<Cart />
						<div className="info-text-italic">
							<Translate id="shipping_cost_added" />
							<br />
							<Translate id="reservation_for_price" />
						</div>
						<br />
						<Button floated="left" onClick={this._emptyCart}>
							<Icon name="cart" /> <Translate id="cart_empty" />
						</Button>
						<Button
							icon
							floated="right"
							primary
							labelPosition="right"
							disabled={nextStepDeactivatedEmptyCart}
							onClick={this._nextStep}
						>
							<Translate id="next_step" />
							<Icon name="chevron right" />
						</Button>
						<div className="clear-float" />
					</Segment>
				);

			case 2:
				return (
					<Segment attached>
						{userExist ? (
							<CheckoutUser />
						) : (
							<Fragment>
								<h2>Logga in eller är du inte kund?</h2>
								<div className="flex-container">
									<div className="flex-1">
										<Login />
									</div>
									<div className="flex-1" />
								</div>
							</Fragment>
						)}
						<Message negative hidden={!this.state.doSummery}>
							Det saknas några uppgifter!
						</Message>
						<Button icon floated="left" labelPosition="left" onClick={this._prevStep}>
							<Translate id="prev_step" />
							<Icon name="chevron left" />
						</Button>

						<Button
							icon
							floated="right"
							disabled={nextStepDeactivated}
							primary
							labelPosition="right"
							onClick={this._nextStep}
						>
							<Translate id="next_step" />
							<Icon name="chevron right" />
						</Button>
						<div className="clear-float" />
					</Segment>
				);

			case 3:
				return (
					<Segment attached>
						<Summary hideHeader={false} />
						<br />
						<Button icon floated="left" labelPosition="left" onClick={this._prevStep}>
							<Translate id="prev_step" />
							<Icon name="chevron left" />
						</Button>
						<Button
							icon
							floated="right"
							primary
							labelPosition="right"
							loading={this.state.sendButtonLoading}
							disabled={this.state.sendButtonLoading}
							onClick={this._sendCart}
						>
							<Translate id="send_order" />
							<Icon name="send" />
						</Button>
						<div className="clear-float" />
					</Segment>
				);

			default:
				return <div />;
		}
	}

	render() {
		const { step } = this.state;
		let segmentToShow = this._getSegment(step);
		return (
			<Fragment>
				<div className="content">
					<div className="flex-1">
						<div>
							<Step.Group stackable="tablet" attached="top" fluid={true}>
								<Step active={step === 1 && true} completed={step > 1 && true}>
									<Icon name="cart" />
									<Step.Content>
										<Step.Title>
											<Translate id="cart" />
										</Step.Title>
										<Step.Description>
											<Translate id="choosen_products" />
										</Step.Description>
									</Step.Content>
								</Step>

								<Step
									active={step === 2 && true}
									disabled={step < 2 && true}
									completed={step > 2 && true}
								>
									<Icon name="truck" />
									<Step.Content>
										<Step.Title>
											<Translate id="customer_data" />
										</Step.Title>
										<Step.Description>
											<Translate id="customer_data_delivery_address" />
										</Step.Description>
									</Step.Content>
								</Step>

								<Step active={step === 3 && true} disabled={step < 3 && true}>
									<Icon name="info" />
									<Step.Content>
										<Step.Title>
											<Translate id="confirm_order" />
										</Step.Title>
										<Step.Description>
											<Translate id="review_order" />
										</Step.Description>
									</Step.Content>
								</Step>
							</Step.Group>
						</div>
						{segmentToShow}
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings,
	cart: state.wccd.cart,
	user: state.user
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ emptyCart, doStep, emptyCheckOutUser, saveOrderForPrint, updateUserPhone }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Checkout));