import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Form, Grid, Header, Dropdown, Step, Segment } from "semantic-ui-react";
import withRouter from "react-router/withRouter";
import {
  withLocalize,
  getTranslate,
  getActiveLanguage,
} from "react-localize-redux";
import axios from "axios";

import config from "../config/config";
import checkUser from "../functions/check-user";

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      first_name: "",
      last_name: "",
      company: "",
      phone: "",
      terms: false,
      email: "",
      street: "",
      street_2: "",
      zip_code: "",
      city: "",
      country: "",
      billing_street: "",
      billing_street_2: "",
      billing_zip_code: "",
      billing_city: "",
      billing_country: "",
      billing_email: "",
      billing_org_number: "",
      sendButtonIsLoading: false,
      activeStep: 1,
      stepsCompleted: 0,
    };
  }

  componentDidMount() {
    let userExist = checkUser(this.props.user);
    if (userExist) {
      this.props.history.replace("/");
    }
  }

  /** Private functions */

  _handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  _handleCheckbox = (e, { name, checked }) => {
    this.setState({ [name]: checked });
  };

  _handleSubmit = (e) => {
    // e.preventDefault();
    const {
      company,
      country,
      first_name,
      last_name,
      email,
      phone,
      city,
      street,
      street_2,
      zip_code,
      billing_street,
      billing_street_2,
      billing_zip_code,
      billing_city,
      billing_country,
      billing_email,
      billing_org_number,
    } = this.state;
    this.setState({
      company: "",
      country: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      city: "",
      street: "",
      street_2: "",
      zip_code: "",
      billing_street: "",
      billing_street_2: "",
      billing_zip_code: "",
      billing_city: "",
      billing_country: "",
      billing_email: "",
      billing_org_number: "",
      sendButtonIsLoading: true,
    });
    console.log("Send reg user", this.state);

    let newUserEndpoint = config.API_ENDPOINTS.POST_NEW_USER;
    let postData = {
      company: company,
      country: country,
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      postal_area: city,
      street: street,
      street_2: street_2,
      zip_code: zip_code,
      billing_street: billing_street,
      billing_street_2: billing_street_2,
      billing_zip_code: billing_zip_code,
      billing_postal_area: billing_city,
      billing_country: billing_country,
      billing_email: billing_email,
      billing_org_number: billing_org_number,
    };
    axios
      .post(newUserEndpoint, postData, config.xhrConfig)
      .then((response) => {
        let resData = response.data;
        console.log("res data: ", resData);
        this.setState({ sendButtonIsLoading: false });
        this.props.history.push("/newuser-success");
      })
      .catch((error) => {
        console.log("error user handleRefresh", error);
        this.props.setError(
          "Fel när vi försökte ladda om användarens uppgifter"
        );
        this.setState({ sendButtonIsLoading: false });
      });
  };

  _readyToSend = () => {
    const state = this.state;
    if (
      state.first_name !== "" &&
      state.last_name !== "" &&
      state.company !== "" &&
      state.terms !== false &&
      state.phone !== "" &&
      state.email !== "" &&
      state.street !== "" &&
      state.zip_code !== "" &&
      state.city !== "" &&
      state.country !== "" &&
      state.billing_street !== "" &&
      state.billing_zip_code !== "" &&
      state.billing_city !== ""
    ) {
      return true;
    }
    return false;
  };

  _getCountries() {
    let selectCountries = [];
    for (var key in this.props.countries) {
      let countryItem = {
        key: key,
        text: this.props.countries[key],
        value: key,
        flag: key.toLowerCase(),
      };
      selectCountries.push(countryItem);
    }
    return selectCountries;
  }

  _goToStep = (newStep) => {
    this.setState({
      activeStep: newStep,
      stepsCompleted: newStep - 1,
    });
  };

  _goToPreviousStep = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
      stepsCompleted: this.state.stepsCompleted - 1,
    });
  };

  _clickedStepLink = (stepClicked) => {
    if (this.state.stepsCompleted >= stepClicked) {
      this.setState({
        activeStep: stepClicked,
        stepsCompleted: stepClicked - 1,
      });
    }
  };

  _step1ReadyToSubmit = () => {
    if (
      this.state.first_name !== "" &&
      this.state.last_name !== "" &&
      this.state.email !== "" &&
      this.state.phone !== "" &&
      this.state.company !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  _step2ReadyToSubmit = () => {
    if (
      this.state.street !== "" &&
      this.state.zip_code !== "" &&
      this.state.city !== "" &&
      this.state.country !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  _step3ReadyToSubmit = () => {
    if (
      this.state.billing_street !== "" &&
      this.state.billing_zip_code !== "" &&
      this.state.billing_city !== "" &&
      this.state.billing_org_number !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const {
      first_name,
      last_name,
      company,
      terms,
      phone,
      email,
      street,
      street_2,
      zip_code,
      city,
      country,
      billing_street,
      billing_street_2,
      billing_zip_code,
      billing_city,
      billing_country,
      billing_email,
      billing_org_number,
      activeStep,
      stepsCompleted,
    } = this.state;
    const checkIfReadyToSend = this._readyToSend();
    const { translate } = this.props;
    const listCountries = this._getCountries();

    let currentLanguageCode = this.props.currentLanguage.code;
    let integrityPolicyLink = "";
    if (currentLanguageCode === "se") {
      integrityPolicyLink =
        "https://spareparts.swepac.com/wp-content/uploads/document_pdf/integritetspolicy_se.pdf";
    } else {
      integrityPolicyLink =
        "https://spareparts.swepac.com/wp-content/uploads/document_pdf/integritetspolicy_en.pdf";
    }

    var onSubmitFunction = () => this._handleSubmit();
    var subStepIsReadyToSubmit = this._step1ReadyToSubmit();

    var step1Active = false;
    var step2Active = false;
    var step3Active = false;
    var step4Active = false;
    if (activeStep === 1) {
      step1Active = true;
      onSubmitFunction = () => this._goToStep(2);
    } else if (activeStep === 2) {
      step2Active = true;
      onSubmitFunction = () => this._goToStep(3);
      subStepIsReadyToSubmit = this._step2ReadyToSubmit();
    } else if (activeStep === 3) {
      step3Active = true;
      onSubmitFunction = () => this._goToStep(4);
      subStepIsReadyToSubmit = this._step3ReadyToSubmit();
    } else if (activeStep === 4) {
      step4Active = true;
    }

    return (
      <div className="content">
        <div className="flex-1">
          <Header as="h2">{translate("create_new_account")}</Header>
          <div className="margin-bottom-20">
            {translate("create_account_process_details")}
          </div>

          <Step.Group ordered widths={4} attached="top">
            <Step
              active={step1Active}
              completed={stepsCompleted >= 1}
              onClick={() => this._clickedStepLink(1)}
            >
              <Step.Content>
                <Step.Title>{translate("customer_data")}</Step.Title>
              </Step.Content>
            </Step>

            <Step
              active={step2Active}
              completed={stepsCompleted >= 2}
              onClick={() => this._clickedStepLink(2)}
            >
              <Step.Content>
                <Step.Title>{translate("delivery_address")}</Step.Title>
              </Step.Content>
            </Step>

            <Step
              active={step3Active}
              completed={stepsCompleted >= 3}
              onClick={() => this._clickedStepLink(3)}
            >
              <Step.Content>
                <Step.Title>{translate("billing_address")}</Step.Title>
              </Step.Content>
            </Step>

            <Step
              active={step4Active}
              completed={stepsCompleted >= 4}
              onClick={() => this._clickedStepLink(4)}
            >
              <Step.Content>
                <Step.Title>{translate("send")}</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>

          <Segment attached>
            <Form size="large" onSubmit={onSubmitFunction}>
              <Grid columns={2} stackable>
                {step1Active && (
                  <Fragment>
                    <Grid.Column>
                      <Header>{translate("customer_data")}</Header>
                      <Form.Input
                        fluid
                        required
                        label={translate("user_firstname")}
                        placeholder={translate("user_firstname")}
                        name="first_name"
                        value={first_name}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={translate("user_lastname")}
                        placeholder={translate("user_lastname")}
                        name="last_name"
                        value={last_name}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={translate("user_email")}
                        placeholder={translate("user_email")}
                        name="email"
                        value={email}
                        onChange={this._handleChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Header>&nbsp;</Header>
                      <Form.Input
                        fluid
                        required
                        label={translate("user_phone")}
                        placeholder={translate("user_phone")}
                        name="phone"
                        value={phone}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={translate("company")}
                        placeholder={translate("company")}
                        name="company"
                        value={company}
                        onChange={this._handleChange}
                      />
                    </Grid.Column>
                  </Fragment>
                )}

                {step2Active && (
                  <Fragment>
                    <Grid.Column>
                      <Header>{translate("delivery_address")}</Header>
                      <Form.Input
                        fluid
                        required
                        label={translate("user_street")}
                        placeholder={translate("user_street")}
                        name="street"
                        value={street}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        label={translate("user_street_2")}
                        placeholder={translate("user_street_2")}
                        name="street_2"
                        value={street_2}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={translate("user_zip_code")}
                        placeholder={translate("user_zip_code")}
                        name="zip_code"
                        value={zip_code}
                        onChange={this._handleChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Header>&nbsp;</Header>
                      <Form.Input
                        fluid
                        required
                        label={translate("user_postal_area")}
                        placeholder={translate("user_postal_area")}
                        name="city"
                        value={city}
                        onChange={this._handleChange}
                      />
                      <Form.Field>
                        <label>
                          {translate("country")}{" "}
                          <span className="mandatory">*</span>
                        </label>
                        <Dropdown
                          placeholder={translate("select_country")}
                          fluid
                          selection
                          name="country"
                          value={country}
                          options={listCountries}
                          onChange={this._handleChange}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Fragment>
                )}

                {step3Active && (
                  <Fragment>
                    <Grid.Column>
                      <Header>{translate("billing_address")}</Header>
                      <Form.Input
                        fluid
                        required
                        label={translate("user_street")}
                        placeholder={translate("user_street")}
                        name="billing_street"
                        value={billing_street}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        label={translate("user_street_2")}
                        placeholder={translate("user_street_2")}
                        name="billing_street_2"
                        value={billing_street_2}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={translate("user_zip_code")}
                        placeholder={translate("user_zip_code")}
                        name="billing_zip_code"
                        value={billing_zip_code}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={translate("user_postal_area")}
                        placeholder={translate("user_postal_area")}
                        name="billing_city"
                        value={billing_city}
                        onChange={this._handleChange}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Header>&nbsp;</Header>
                      <Form.Field>
                        <label>{translate("country")}</label>
                        <Dropdown
                          placeholder={translate("select_country")}
                          fluid
                          selection
                          name="billing_country"
                          value={billing_country}
                          options={listCountries}
                          onChange={this._handleChange}
                        />
                      </Form.Field>
                      <Form.Input
                        fluid
                        label={translate("billing_email")}
                        placeholder={translate("billing_email")}
                        name="billing_email"
                        value={billing_email}
                        onChange={this._handleChange}
                      />
                      <Form.Input
                        fluid
                        required
                        label={translate("billing_org_number")}
                        placeholder={translate("billing_org_number")}
                        name="billing_org_number"
                        value={billing_org_number}
                        onChange={this._handleChange}
                      />
                    </Grid.Column>
                  </Fragment>
                )}
              </Grid>
              <Grid>
                {!step4Active && (
                  <Grid.Column textAlign="right">
                    <Form.Button
                      disabled={!subStepIsReadyToSubmit}
                      icon="send"
                      content={translate("next_step")}
                      size="large"
                      color="blue"
                    />
                  </Grid.Column>
                )}
                {step4Active && (
                  <Grid.Column textAlign="right">
                    <Form.Field>
                      <p>
                        <a
                          href={integrityPolicyLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {translate("read_gdpr_document")}
                        </a>
                      </p>
                      <Form.Checkbox
                        label={translate("accept_registration_terms")}
                        name="terms"
                        inline
                        required
                        checked={terms}
                        onChange={this._handleCheckbox}
                      />
                    </Form.Field>
                    <Form.Button
                      disabled={!checkIfReadyToSend}
                      icon="send"
                      content={translate("send")}
                      size="large"
                      color="blue"
                      loading={this.state.sendButtonIsLoading}
                    />
                    <iframe
                      src={integrityPolicyLink}
                      title="Integrity Policy"
                      style={{
                        boxSizing: "border-box",
                        width: "100%",
                        border: "none",
                        height: "400px",
                      }}
                    />
                  </Grid.Column>
                )}
                {/* {step4Active &&
									<Grid.Column style={{width:"100%"}}>
										<div>          
			  								<iframe src={integrityPolicyLink} style={{width:"100%",border:"none",height:"400px"}} />
										</div>
									</Grid.Column>
								} */}
              </Grid>
            </Form>
          </Segment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  countries: state.wccd.countries,
  translate: getTranslate(state.localize),
  currentLanguage: getActiveLanguage(state.localize),
});

NewUser = withLocalize(NewUser);
NewUser = connect(mapStateToProps)(NewUser);
NewUser = withRouter(NewUser);

export default NewUser;
