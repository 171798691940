export const LOADING_POSTS = 'LOADING_POSTS';
export const SAVE_ALL_POSTS = 'SAVE_ALL_POSTS';
export const LOADING_PAGES = 'LOADING_PAGES';
export const SAVE_ALL_PAGES = 'SAVE_ALL_PAGES';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_ALL_WCCD = 'GET_ALL_WCCD';
export const UPDATE_SELECTED_CURRENCY = 'UPDATE_SELECTED_CURRENCY';

export const IS_LOADING = 'IS_LOADING';
export const SET_ERROR = 'SET_ERROR';

export const SELECTED_PRODUCT = 'SELECTED_PRODUCT';
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const CHANGE_QUANTITY_IN_CART = 'CHANGE_QUANTITY_IN_CART';
export const REMOVE_ITEM_IN_CART = 'REMOVE_ITEM_IN_CART';
export const EMPTY_CART = 'EMPTY_CART';
export const EMPTY_CHECKOUT_USER = 'EMPTY_CHECKOUT_USER';
export const SAVE_ORDER_FOR_PRINT = 'SAVE_ORDER_FOR_PRINT';

export const DO_STEP = 'DO_STEP';
export const UPDATE_SETTING = 'UPDATE_SETTING';
export const TOGGLE_CART_EDIT = 'TOGGLE_CART_EDIT';

export const UPDATE_USER = 'UPDATE_USER';
export const REFRESH_USER = 'REFRESH_USER';
export const BATCH_UPDATE_USER = 'BATCH_UPDATE_USER';
export const UPDATE_CHECKOUT_USER = 'UPDATE_CHECKOUT_USER';
export const UPDATE_CHECKOUT_USER_BILLING = 'UPDATE_CHECKOUT_USER_BILLING';
export const UPDATE_CHECKOUT_USER_DELIVERY = 'UPDATE_CHECKOUT_USER_DELIVERY';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SAVE_ORDER_ADMIN_ORDERS = 'SAVE_ORDER_ADMIN_ORDERS';
export const ORDER_ADMIN_UPDATE_ORDER = 'ORDER_ADMIN_UPDATE_ORDER';
export const ORDER_ADMIN_GET_USERS = 'ORDER_ADMIN_GET_USERS';
export const ORDER_ADMIN_USERS_ACTIVE_TAB = 'ORDER_ADMIN_USERS_ACTIVE_TAB';
export const ORDER_ADMIN_DISPLAY_NONEXPORT_ORDERS = 'ORDER_ADMIN_DISPLAY_NONEXPORT_ORDERS';
export const ORDER_ADMIN_ORDERS_ACTIVE_TAB = 'ORDER_ADMIN_ORDERS_ACTIVE_TAB';
export const ORDER_ADMIN_ORDERS_CHANGE_COUNTRIES = 'ORDER_ADMIN_ORDERS_CHANGE_COUNTRIES';

export const LOAD_SAVED_DATA_AFTER_UPDATE_RESTART = 'LOAD_SAVED_DATA_AFTER_UPDATE_RESTART';
export const UPDATE_USER_PHONE = 'UPDATE_USER_PHONE';