import sortList from './sort-list';

const renderTree = (props, selectedSlug) => {
	let returnArray = {};
	// console.log(props);

	if (props.length === 0) {
		return null;
	}

	let categories = JSON.parse(JSON.stringify(props));
	categories.sort(sortList('pos_num'));
	categories.sort(function(a, b) {
		return a.pos_num - b.pos_num;
	});

	for (let i = 0, arrayLength = categories.length; i < arrayLength; i++) {
		let item = categories[i];
		item.children = null;
	}
	list_to_tree(categories);
	// console.log('list_to_tree', categories);

	for (let i = 0, arrayLength = categories.length; i < arrayLength; i++) {
		let item = categories[i];
		//console.log('item', item);

		if (item.parent === 0 && selectedSlug === item.slug) {
			//item.toggled = true;
			returnArray = item.children;
		}
	}
	if (!Object.keys(returnArray).length === true) {
		return null;
	}

	return returnArray;
};

function list_to_tree(list) {
	var map = {},
		node,
		roots = [],
		i;
	for (i = 0; i < list.length; i += 1) {
		map[list[i].id] = i;
		list[i].children = [];
	}
	for (i = 0; i < list.length; i += 1) {
		node = list[i];
		if (node.parent !== '0') {
			if (map[node.parent] !== undefined) {
				list[map[node.parent]].children.push(node);
			}
		} else {
			//console.log('node.parent === 0');
			roots.push(node);
		}
	}
	return roots;
}

export default renderTree;
