import React from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';

const CurrencyItem = (props) => {
    return (
        <Dropdown
			item
			simple={props.simple}
			fitted="vertically"
			trigger={
				<span>
					<Icon name="money bill alternate outline" /> <Translate id="currency" />
				</span>
			}
			options={props.currenciesToSelect}
			value={props.currentCurrencyId}
			onChange={props.handleCurrencySelect}
			className="top-menu-item"
		/>
    );
};

export default CurrencyItem;