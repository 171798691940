/**
 * Formatera värde till sträng och float
 * @param {float} value
 * @param {string} currency
 * @returns {obj} { floatPrice, strPrice }
 */
const currency = (value, currency = "sek", currencySymbol = "") => {
  let strPriceValue = 0;
  let floatPriceValue = 0;
  if (String(value).includes(",") > 0 && currency === "sek") {
    strPriceValue = parseFloat(value.replace(",", "."));
    floatPriceValue = strPriceValue;
  } else {
    strPriceValue = parseFloat(value);
    floatPriceValue = strPriceValue;
  }
  strPriceValue = formatCurrency(strPriceValue, currency, currencySymbol);
  return { floatPrice: floatPriceValue, strPrice: strPriceValue };
};

/**
 * Formatera om, beroende på värde av valuta
 * @param {float} value, Värde
 * @param {string} currency, Vilken typ av valuta
 * @returns {string} Färdigformaterad sträng med valuta
 */
export const formatCurrency = (
  value,
  currency = "sek",
  currencySymbol = ""
) => {
  let strPriceValue = 0;
  if(isNaN(value)) {
    strPriceValue = '-';
  } else if (currency === "sek") {
    strPriceValue = new Intl.NumberFormat("sv-SE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    strPriceValue = strPriceValue + " " + currencySymbol;
  } else if (currency === "eur") {
    strPriceValue = new Intl.NumberFormat("et-EE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    strPriceValue = currencySymbol + strPriceValue;
  } else {
    strPriceValue = new Intl.NumberFormat("sv-SE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    strPriceValue = strPriceValue + " " + currencySymbol;
  }
  return strPriceValue;
};

export default currency;
