import React, { Fragment } from "react";
import { Menu, Button, Icon, Label, Popup } from "semantic-ui-react";
import { Translate } from "react-localize-redux";

const CartButton = (props) => {
  const itemToRender = (
    <Popup
      trigger={
        <Button
          as="div"
          labelPosition="right"
          disabled={!props.userExist}
          onClick={props.toogleModal}
		  className="top-cart-button"
        >
          <Button basic>
            <Icon name="in cart" />
            {!props.hideCartButtonText && <Translate id="cart" />}
          </Button>
          <Label as="a" basic color="red" pointing="left">
            {props.userExist ? props.countCart.totalCount : 0}
          </Label>
        </Button>
      }
      content={
        <Fragment>
          {props.state.noOfProducts} <Translate id="popup_no" />{" "}
          {props.state.addedProduct} <Translate id="popup_no_added" />
        </Fragment>
      }
      inverted
      open={props.state.newProductPopup || props.state.fading}
      className={`transition fade ${
        props.state.newProductPopup ? "in custom-popup" : "out custom-popup"
      }`}
      position="bottom right"
    />
  );

  if (props.position !== "") {
    return <Menu.Item position="right">{itemToRender}</Menu.Item>;
  } else {
    return (
      <Menu.Item fitted="horizontally" style={{ marginLeft: "-10px" }}>
        {itemToRender}
      </Menu.Item>
    );
  }
};

export default CartButton;
