import React, { Component, Fragment } from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { Menu, Grid, Icon, Loader, Dimmer } from "semantic-ui-react";
import withRouter from "react-router/withRouter";
import { withLocalize, getActiveLanguage } from "react-localize-redux";

import config from "../config/config";
import axios from "axios";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeHomepageLangCode: "",
      homePageContentHtmlData: "",
    };
  }

  getHomepageContent = async (langCode) => {
    const chosenLanguage = this.props.match.params.countrycode || "";
    const urlPath =
      config.API_ENDPOINTS.GET_HOMEPAGE_CONTENT + "?langcode=" + langCode;
    Promise.all([await axios.get(urlPath)])
      .then((resp) => {
        if (resp) {
          const response = resp[0].data;
          this.setState({
            homePageContentHtmlData: response.post_html_content,
            activeHomepageLangCode: this.props.currentLanguage.code,
          });

          if (chosenLanguage !== "") {
            setTimeout(() => {
              this.props.history.push("/");
            }, 1000);
          }
        }
      })
      .catch((error) => {
        console.log("error getHomepageContent", error);
      });
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    const chosenLanguage = this.props.match.params.countrycode || "";
    let selectedLangCode = "";
    if (this.props.currentLanguage) {
      if (this.props.currentLanguage.code) {
        selectedLangCode = this.props.currentLanguage.code;
      }
    }
    if (chosenLanguage !== "") {
      if (selectedLangCode !== chosenLanguage) {
        this.props.setActiveLanguage(chosenLanguage);
        selectedLangCode = chosenLanguage;
      }
    }

    if (this.props.currentLanguage) {
      if (
        this.props.currentLanguage.code !== this.state.activeHomepageLangCode
      ) {
        this.getHomepageContent(selectedLangCode);
      }
    }
  }

  render() {
    return (
      <Fragment>
        <div className="wccd-hero-image">
          <p className="wccd-hero-image-text">
            {!this.props.user.isOrderManager && !this.props.user.isCeAdmin && (
              <Translate id="welcome" />
            )}
            {this.props.user.isOrderManager && (
              <Fragment>
                <Translate id="orderadmin_order_management_header_title" />
              </Fragment>
            )}
            {this.props.user.isCeAdmin && (
              <Fragment>Swepacs Admin-panel för CE/Motorintyg</Fragment>
            )}
          </p>
        </div>

        {!this.props.user.isOrderManager && !this.props.user.isCeAdmin && (
          <Fragment>
            <div className="content" style={{ marginTop: 210 }}>
              <Dimmer
                inverted
                active={this.state.homePageContentHtmlData === ""}
              >
                <Loader />
              </Dimmer>
              <div
                style={{ fontSize: "1.2em" }}
                dangerouslySetInnerHTML={{
                  __html: this.state.homePageContentHtmlData,
                }}
              />
              {/* <div className="flex-1">
						{currentLanguageCode == "se" &&
							<h1 className="text-align-center">RESERVDELAR</h1>
						}
						{currentLanguageCode == "en" &&
							<h1 className="text-align-center">SPARE PARTS</h1>
						}
						{currentLanguageCode == "se" &&
							<p style={{ fontSize: '1.2em' }}>
								Här kan du lätt beställa reservdelar men det går naturligtvis lika bra att kontakta vår
								orderavdelning vid centrallagret i Ljungby på tel <strong>0372-156 05</strong>.<br />
								<br />För webbeställning av reservdelar klickar du på "Alla produkter" och väljer produkttyp,
								därefter guidas du fram via tydliga sprängskisser över produkterna, väljer reservdel(ar) och
								antal samt går vidare till kunddata och leveransadress.<br />
								<br />
							</p>
						}
						{currentLanguageCode == "en" &&
							<p style={{ fontSize: '1.2em' }}>
								Here you can order spare parts easily, or, if you want, you can phone the sales department at our
								central warehouse in Ljungby at <strong>0372-156 05</strong>.<br />
								<br />To order spare parts online, click “All Products”, select the product type,
								follow the exploded-view diagrams of the products, choose the spare part(s), the quantity,
								and then enter your customer data and delivery address.<br />
								<br />
							</p>
						}

						<Message color='yellow'>
							<Grid columns={2} stackable>
								<Grid.Column width={12}>
								{currentLanguageCode == "se" &&
									<Message.Header>OBS! Reservdelsportalen fungerar bäst med webbläsarna: Chrome, Firefox eller Safari</Message.Header>
								}
								{currentLanguageCode == "en" &&
									<Message.Header>N.B.! The spare parts platform works best with the following browsers: Chrome, Firefox or Safari</Message.Header>
								}
								</Grid.Column>
								<Grid.Column width={4} align='right'>
									<Icon name='chrome' size='big' />
									<Icon name='firefox' size='big' />
									<Icon name='safari' size='big' />
								</Grid.Column>
							</Grid>
						</Message>
						<br />

						{currentLanguageCode == "se" &&
							<h2>
								Innovation och hög produktkvalitet<img
									src="https://www.swepac.com/assets/2017/10/Swepac_front3B_web.jpg"
									alt=""
									width="50%"
									align="right"
									style={{ marginLeft: 35 }}
								/>
							</h2>
						}
						{currentLanguageCode == "en" &&
							<h2>
								Innovation and high-quality products<img
									src="https://www.swepac.com/assets/2017/10/Swepac_front3B_web.jpg"
									alt=""
									width="50%"
									align="right"
									style={{ marginLeft: 35 }}
								/>
							</h2>
						}
						{currentLanguageCode == "se" &&
							<p>
								Swepac är ett ledande helsvenskt företag som utvecklar och tillverkar produkter för jordpackning
								och betongbearbetning. Produkterna är utvecklade i nära samarbete med kunder och har starkt
								fokus på funktion, säkerhet, god ergonomi, hög kvalitet och ett medvetet miljötänkande.<br />
								<br />
							</p>
						}
						{currentLanguageCode == "en" &&
							<p>
								Swepac is a leading Swedish company that develops and manufactures products for light compaction
								and concrete treatment equipment. We develop our products in close collaboration with our customers and have a strong
								focus on excellent functionality, safety, superior ergonomics, high quality and environmental awareness.<br />
								<br />
							</p>
						}
						{currentLanguageCode == "se" &&
							<Fragment>
							<h2>Heltäckande produktprogram inom jordpackning</h2>
							<p>
								Många års erfarenhet av att leverera maskiner till ledande hyresbolag har lärt oss hur viktig
								pålitlighet och produktkvalitet är. Alla våra produkter byggs för att klara svåra förhållanden,
								vara enkla att använda och underhålla samt ge en god avkastning på investeringen för våra
								kunder.<br />
								<br />Det heltäckande produktprogramet omfattar framåtgående samt fram/backgående markvibratorer
								drivna med med batteri, bensin eller diesel. Markvibratorerna kompletteras av stampar och
								vältar.
							</p>
							</Fragment>
						}
						{currentLanguageCode == "en" &&
							<Fragment>
							<h2>Full light compaction product range</h2>
							<p>
								Many years of experience in delivering machines to leading rental companies have taught us the importance
								of being reliable and quality oriented. All our products are built for harsh conditions,
								ease of use and service, and a good return on our customers’ investment.<br />
								<br />Our extensive range of compaction equipment consists of forward and reversible vibratory plates
								powered by battery, petrol or diesel. The vibratory plates are complemented by tampers and rollers.
							</p>
							</Fragment>
						}
					</div> */}
            </div>
          </Fragment>
        )}
        {(this.props.user.isOrderManager || this.props.user.isCeAdmin) && (
          <Fragment>
            <div className="content" style={{ marginTop: 210 }}>
              <Grid columns={4} stackable>
                {this.props.user.isOrderManager && (
                  <Grid.Column>
                    <b>
                      <Translate id="orderadmin_order_management" />:
                    </b>
                    <Menu vertical>
                      <Menu.Item
                        onClick={() =>
                          this.props.history.push("/order-admin/orders")
                        }
                      >
                        <Icon name="boxes" />
                        <Translate id="orderadmin_manage_orders" />
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          this.props.history.push("/order-admin/users")
                        }
                      >
                        <Icon name="users" />
                        <Translate id="orderadmin_manage_users" />
                      </Menu.Item>
                    </Menu>
                  </Grid.Column>
                )}
                {this.props.user.isCeAdmin && (
                  <Grid.Column style={{ width: "100%" }}>
                    <b>CE/Motorintyg-admin:</b>
                    <Menu vertical style={{ width: "100%" }}>
                      <Menu.Item
                        onClick={() =>
                          this.props.history.push("/ce-admin/print-list")
                        }
                      >
                        <Icon name="print" />
                        Utskriftskö för senaste CE/Motorintyg
                      </Menu.Item>
                    </Menu>
                  </Grid.Column>
                )}
              </Grid>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  currentLanguage: getActiveLanguage(state.localize),
});

Home = withLocalize(Home);
Home = connect(mapStateToProps)(Home);
Home = withRouter(Home);
export default Home;
