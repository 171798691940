const updateProducts = (cart, existingProducts, newProducts) => {
	let productsArray = JSON.parse(JSON.stringify(existingProducts));
	let cartArray = JSON.parse(JSON.stringify(cart));

	for (let i = 0; i < newProducts.length; i++) {
		const product = newProducts[i];
		let prodIndex = existingProducts.findIndex((existProduct) => {
			return existProduct.id === product.id;
		});
		// console.log('found index id === id', prodIndex);
		if (prodIndex < 0) {
			// Kolla om produkt finns med samma sku-nr men med olika id-nr,
			// i sådanfall ta bort den ur productsArray.
			let prodIndexExist = existingProducts.findIndex((existProduct) => {
				return existProduct.id !== product.id && existProduct.sku === product.sku;
			});
			if (prodIndexExist >= 0) {
				productsArray[prodIndexExist] = product;

				let cartIndex = cart.findIndex((existProduct) => {
					return existingProducts[prodIndexExist].id === existProduct.productId;
				});
				if (cartIndex >= 0) {
					cartArray[cartIndex]['productId'] = product.id;
				}
			} else {
				productsArray.push(product);
			}
		} else {
			productsArray[prodIndex] = product;
		}
	}

	return { productsArray, cartArray };
};

export default updateProducts;
