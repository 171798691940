import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withLocalize, getActiveLanguage } from "react-localize-redux";
import axios from "axios";
import {
  Container,
  Grid,
  Modal,
  Button,
  Icon,
  Header,
  Responsive,
  Divider,
} from "semantic-ui-react";
import { Translate } from "react-localize-redux";

import config from "./config/config";

import { allWccd } from "./actions/wccd-content";
import { setError, loadSavedDataAfterUpdate } from "./actions/settings-action";

import TreeMenu from "./container/tree-menu";
import PageHeaderResponsive from "./container/page-header-responsive";

import resetApp from "./functions/reset-app";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTranslations: this._getTranslations(),
      showRestart: false,
      showError: props.settings.error !== "" ? true : false,
      displayMobileTreeMenu: false,
    };
  }

  componentDidMount() {
    if (this.props.settings.version !== config.version) {
      localStorage.setItem(
        "savedUserData",
        JSON.stringify(this.props.userState)
      );
      localStorage.setItem(
        "savedCartItems",
        JSON.stringify(this.props.wccd.cart)
      );
      localStorage.setItem(
        "savedProductItems",
        JSON.stringify(this.props.wccd.products)
      );
      localStorage.setItem("hasLoadedRestartData", "false");
      this.setState({ showRestart: true });
    } else {
      if (localStorage.getItem("hasLoadedRestartData") !== null) {
        if (localStorage.getItem("hasLoadedRestartData") === "false") {
          if (
            localStorage.getItem("savedUserData") !== null &&
            localStorage.getItem("savedCartItems") !== null
          ) {
            var saveUserData = JSON.parse(
              localStorage.getItem("savedUserData")
            );
            var saveCartItems = JSON.parse(
              localStorage.getItem("savedCartItems")
            );
            var saveProductItems = JSON.parse(
              localStorage.getItem("savedProductItems")
            );
            localStorage.setItem("hasLoadedRestartData", "true");
            this.props.loadSavedDataAfterUpdate(
              saveUserData,
              saveCartItems,
              saveProductItems
            );
          }
        } else {
          localStorage.removeItem("savedUserData");
          localStorage.removeItem("savedCartItems");
          localStorage.removeItem("savedProductItems");
          localStorage.removeItem("hasLoadedRestartData");
        }
      }
    }

    if (!this.state.loadingTranslations) {
      let newUpdateTime = Date.now();
      let updateTime = this.props.wccd.lastUpdate;
      if (updateTime + 30 * 1000 >= newUpdateTime) {
        const ele = document.getElementById("ipl-progress-indicator");
        if (ele) {
          // fade out
          ele.classList.add("available");
          setTimeout(() => {
            // remove from DOM
            ele.outerHTML = "";
          }, 2000);
        }
      } else {
        const selectedProduct = this.props.selectedProduct.hasOwnProperty(
          "name"
        )
          ? this.props.selectedProduct
          : null;
        this.props.allWccd(selectedProduct);
      }
    }
  }

  componentDidUpdate() {
    if (!this.state.loadingTranslations) {
      if (Object.keys(this.props.wccd.countries).length !== 0) {
        const ele = document.getElementById("ipl-progress-indicator");
        if (ele) {
          ele.classList.add("available");
        }
      }
    }
    if (this.props.settings.error !== "" && !this.state.showError) {
      console.log("Show error");
      this.setState({ showError: true });
    }
  }

  /** Private functions */

  _reloadWindow = () => {
    this.props.setError("");
    window.location.href = "/";
  };

  _restartApp = () => {
    resetApp();
    this.setState({ showRestart: false });
  };

  _getTranslations() {
    let getCurrentLanguageId = "en";
    if (this.props.currentLanguage !== undefined) {
      getCurrentLanguageId = this.props.currentLanguage.code;
    }
    axios
      .get(config.API_ENDPOINTS.GET_LANGUAGE_FRONTEND, config.xhrConfig)
      .then((res) => {
        this.props.initialize({
          languages: res.data.languages,
          translation: res.data.translations[0],
          options: {
            renderToStaticMarkup: false,
            renderInnerHtml: true,
            defaultLanguage: getCurrentLanguageId,
          },
        });
        return false;
      })
      .catch((error) => {
        console.log("error getTranslations", error);
        return true;
      });
  }

  _checkPathForSideMenu() {
    const { pathname } = this.props.location;
    const currentPath = pathname.split("/")[1];

    if (this.props.settings.pages !== undefined) {
      if (this.props.settings.pages.indexOf(currentPath) >= 0) {
        return { showMenu: true, col1: 4, col2: 12 };
      } else {
        return { showMenu: false, col1: 0, col2: 16 };
      }
    }
  }

  _toggleMobileTreeMenuDisplay(newValue) {
    this.setState({
      displayMobileTreeMenu: newValue,
    });
  }

  render() {
    const showSideMenu = this._checkPathForSideMenu();
    let displayPageHeader = true;
    if (this.props.location.pathname === "/ce-och-motorintyg-sok") {
      displayPageHeader = false;
    }
    let marginTopValue = 72;
    if (!displayPageHeader) {
      marginTopValue = 0;
    }

    if (this.state.showRestart) {
      this._restartApp();
    }

    return (
      <Fragment>
        <Responsive as={Fragment} minWidth={1000}>
          {displayPageHeader && <PageHeaderResponsive />}
          <Container>
            <Grid style={{ marginTop: marginTopValue }}>
              {showSideMenu.showMenu && (
                <Grid.Column width={showSideMenu.col1} className="no-print">
                  <TreeMenu isOnMobile={false} />
                </Grid.Column>
              )}
              <Grid.Column width={showSideMenu.col2} className="center-print">
                {this.props.children}
                <div className="clear-float" />
              </Grid.Column>
            </Grid>
          </Container>
        </Responsive>

        <Responsive as={Fragment} maxWidth={999} minWidth={0}>
          {displayPageHeader && <PageHeaderResponsive />}
          <Container>
            <Grid style={{ marginTop: marginTopValue }}>
              <Grid.Column width={16} className="center-print">
                {showSideMenu.showMenu && (
                  <Button
                    onClick={() =>
                      this._toggleMobileTreeMenuDisplay(
                        !this.state.displayMobileTreeMenu
                      )
                    }
                    fluid
                    color="red"
                    style={{ marginTop: "5px", marginBottom: "2px" }}
                  >
                    {this.state.displayMobileTreeMenu && (
                      <Fragment>
                        <Translate id="close_product_category_menu" />
                      </Fragment>
                    )}
                    {!this.state.displayMobileTreeMenu && (
                      <Fragment>
                        <Translate id="open_product_category_menu" />
                      </Fragment>
                    )}
                  </Button>
                )}
                {this.state.displayMobileTreeMenu && showSideMenu.showMenu && (
                  <TreeMenu isOnMobile={true} />
                )}
                {showSideMenu.showMenu && <Divider />}
                {this.props.children}
                <div className="clear-float" />
              </Grid.Column>
            </Grid>
          </Container>
        </Responsive>

        <Modal open={this.state.showRestart} basic size="small">
          <Header
            icon="info circle"
            content={"New version of the App (v" + config.version + ")"}
          />
          <Modal.Content>
            <h3>
              You are using an old version of the app and we need to restart!
            </h3>
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" onClick={this._restartApp} inverted>
              <Icon name="checkmark" /> Got it
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showError} basic size="small">
          <Header icon="frown" content={"An error occured!"} />
          <Modal.Content>
            <h3>More info:</h3>
            {this.props.settings.error}
          </Modal.Content>
          <Modal.Actions>
            <Button color="green" inverted onClick={this._reloadWindow}>
              <Icon name="redo" /> Reload app
            </Button>
            <Button color="red" onClick={this._restartApp} inverted>
              <Icon name="ambulance" /> Reset app and restart!
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

App.defaultProps = {
  children: null,
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  wccd: state.wccd,
  userState: state.user,
  currentLanguage: getActiveLanguage(state.localize),
  selectedProduct: state.wccd.selectedProduct,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { allWccd, setError, loadSavedDataAfterUpdate },
    dispatch
  );
};

App = withLocalize(App);
App = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
App = withRouter(App);

export default App;
