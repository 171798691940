import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withLocalize,
  getTranslate,
  getActiveLanguage,
} from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import TreeListItem from "../components/tree-list-item";
import ServiceMenuTreeListItem from "../components/service-menu-tree-list-item";
import renderTree from "../functions/render-tree";

class TreeMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodeData: {},
      menuDisplay: { maxHeight: 0 },
      menuOpen: false,
      activeNode: "",
      showLoadedReady: false,
      loadDone: false,
      windowHeightValue: window.innerHeight,
      activeMenu: "spareparts",
      savedServiceInfoJsonData: "",
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    if (this.state.showLoadedReady) {
      // Ta bort eventuell timer
    }
    window.removeEventListener("resize", this.resize);
  }

  componentDidUpdate(prevProps, nextProps) {
    if (
      this.props.location.pathname ===
        "/category/" + this.props.selectedProduct.slug &&
      this.state.activeNode !== ""
    ) {
      this.setState({ activeNode: "" });
    }

    if (
      this.props.location.pathname ===
        "/category/" + this.props.selectedProduct.slug &&
      this.state.activeServiceMenuNode !== ""
    ) {
      this.setState({ activeServiceMenuNode: "" });
    }

    if (
      !this.props.isLoading &&
      !this.state.showLoadedReady &&
      !this.state.loadDone
    ) {
      this.setState({ showLoadedReady: true, loadDone: true });
      setTimeout(() => {
        if (this.state.showLoadedReady) {
          this.setState({ showLoadedReady: false });
        }
      }, 3000);
    }
  }

  resize = () => {
    this.setState({
      windowHeightValue: window.innerHeight,
    });
  };

  handleCategoryClick = (itemId) => {
    this.setState({ activeNode: itemId });
  };

  handleServiceCategoryClick = (itemId) => {
    this.setState({ activeServiceMenuNode: itemId });
  };

  /** Private functions */

  _displayMainCategory = () => {
    this.setState({ activeNode: "" });
    this.props.history.push("/category/" + this.props.selectedProduct.slug);
  };

  _showLoader = () => {
    return this.props.isLoading
      ? { float: "right" }
      : { float: "right", display: "none" };
  };

  _getMachineServiceInfo(mainProductSlug) {
   /*  let urlPath =
      config.API_ENDPOINTS.GET_MAIN_PRODUCT_SERVICE_INFO_MENU + mainProductSlug;
    Promise.all([axios.get(urlPath)])
      .then((resp) => {
        if (resp) {
          let saveData = "";
          if (resp[0].data !== false) {
            saveData = resp[0].data;
            if (this.state.savedServiceInfoJsonData === "") {
              this.setState({
                savedServiceInfoJsonData: JSON.stringify(saveData),
              });
            }
          }
        } else {
          this.setState({ serviceInfoHtml: "" });
        }
      })
      .catch((error) => {}); */
  }

  render() {
    let renderedTree = null;
    let showTreeView = false;
    const { name, slug, img } = this.props.selectedProduct;
    let langName = "";
    try {
      langName = JSON.parse(name)[this.props.currentLanguage.code];
    } catch {}
    const showLoader = this._showLoader();
    const showLoadedReady = this.state.showLoadedReady;

    if (slug !== undefined) {
      renderedTree = renderTree(this.props.categoriesArray[slug], slug);
    }

    if (renderedTree !== null) {
      showTreeView = true;
    }

    var extraTreeClassName = "";
    var maxHeightValue = "none";
    if (this.props.isOnMobile) {
      extraTreeClassName = "tree-menu-on-mobile";
    } else {
      extraTreeClassName = "tree-menu-on-desktop";
      maxHeightValue = this.state.windowHeightValue - 85 + "px";
    }

    if (this.state.savedServiceInfoJsonData === "") {
      this._getMachineServiceInfo(this.props.selectedProduct.slug);
    }

    return (
      <div
        className={"menu treeMenu " + extraTreeClassName}
        style={{ maxHeight: maxHeightValue, marginTop: "10px" }}
      >
        <div className="main-products menu-padding">
          <div className="font-bold text-transform-upper menu-item-padding">
            {this.props.translation("choose_products")}
            <span style={showLoader}>
              <Icon loading name="spinner" />
            </span>
            <span style={{ float: "right", color: "#8B8" }}>
              {showLoadedReady && <Icon name="check" />}
            </span>
          </div>
        </div>
        <div className="tree-prod-img-container">
          <img src={img} alt={langName} className="tree-prod-img" />
        </div>

        {/* 
				
				COMMENTED OUT UNTIL THIS FUNCTIONALITY IS NEEDED:
				
				<Button.Group fluid style={{ marginBottom: "20px" }}>
					<Button
						basic
						color="red"
						active={this.state.activeMenu === "spareparts"}
						onClick={() => this.setState({
							activeMenu: "spareparts"
						})}
					>Spare parts</Button>
					<Button
						basic
						color="red"
						active={this.state.activeMenu === "serviceinfo"}
						onClick={() => this.setState({
							activeMenu: "serviceinfo"
						})}
					>Service info</Button>
				</Button.Group>
				 */}
        <div className="menu-item-padding tree">
          {this.state.activeMenu === "spareparts" && showTreeView && (
            <p className="tree" onClick={this._displayMainCategory}>
              {langName}
            </p>
          )}
          {this.state.activeMenu === "serviceinfo" && (
            <p className="tree">{langName} Service info</p>
          )}
          <ul className="tree">
            {this.state.activeMenu === "spareparts" &&
              showTreeView &&
              renderedTree.map((item, index) => {
                return (
                  <TreeListItem
                    key={index}
                    handleCategoryClick={this.handleCategoryClick}
                    props={item}
                    activeNode={this.state.activeNode}
                    currentLanguageCode={this.props.currentLanguage.code}
                  />
                );
              })}
            {this.state.activeMenu === "serviceinfo" &&
              this.state.savedServiceInfoJsonData !== "" &&
              this.state.savedServiceInfoJsonData !== "[]" &&
              JSON.parse(this.state.savedServiceInfoJsonData).map(
                (item, index) => {
                  item.slug = item.id;
                  return (
                    <ServiceMenuTreeListItem
                      key={index}
                      handleCategoryClick={this.handleServiceCategoryClick}
                      props={item}
                      activeNode={this.state.activeServiceMenuNode}
                      currentLanguageCode={this.props.currentLanguage.code}
                    />
                  );
                }
              )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  categoriesArray: state.wccd.categoriesArray,
  isLoading: state.wccd.isLoading,
  isLoaded: state.wccd.isLoaded,
  selectedProduct: state.wccd.selectedProduct,
  translation: getTranslate(state.localize),
  currentLanguage: getActiveLanguage(state.localize),
});

const withLocalization = withLocalize(TreeMenu);
export default withRouter(connect(mapStateToProps)(withLocalization));
