import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize, getTranslate } from 'react-localize-redux';
import withRouter from 'react-router/withRouter';
import { setActiveLanguage } from 'react-localize-redux';

import { Form, Grid, Header, Message, Segment } from 'semantic-ui-react';
import axios from 'axios';
import md5 from 'md5';

import config from '../config/config';
import { loginUser, getOrderAdminOrders, getOrderAdminUsers } from '../actions/user-action';
import { updateActiveCurrency } from '../actions/wccd-content';

class LoginForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			email: '',
			msgHidden: true,
			msgColor: 'green',
			msg: 'new_password_sent',
			sending: false,
			forgottenPassword: false
		};
	}

	/** Private functions */

	_handleChange = (e, { name, value }) => {
		this.setState({ [name]: value, msgHidden: true });
	};

	_forgottenPassword = () => {
		this.setState({ forgottenPassword: true });
	};

	_handlePasswordSubmit = (e) => {
		e.preventDefault();
		this.setState({ sending: true });
		let userData = {
			email: this.state.email
		};

		axios
			.post(config.API_ENDPOINTS.POST_GET_NEW_PASSWORD, userData, config.xhrConfig)
			.then((res) => {
				if (res.data.hasOwnProperty('sent')) {
					let userProps = Object.assign({}, res.data);
					if (userProps.sent === 'true') {
						this.setState({ msgHidden: false, sending: false, email: '' });
						setTimeout(() => {
							this.setState({ forgottenPassword: false, msgHidden: true });
						}, 3000);
					}
				} else {
					this.setState({ msgHidden: false, msg: 'error', msgColor: 'red', sending: false });
					console.log('error new password axios');
				}
			})
			.catch((error) => {
				this.setState({ msgHidden: false, msg: 'error', sending: false });
				console.log('error login handleSubmit', error);
			});
	};

	_handleSubmit = (e) => {
		e.preventDefault();
		const { password, email } = this.state;
		this.setState({ password: '', email: '' });
		let userData = {
			username: email,
			password: password
		};
		axios
			.post(config.API_ENDPOINTS.POST_USER, userData, config.xhrConfig)
			.then((res) => {
				if (res.data.hasOwnProperty('user_id')) {
					let userProps = Object.assign({}, res.data);
					userProps.email = email;
					userProps.guest = md5(email + ':' + userProps.user_id);
					this.props.loginUser(userProps);
					if (userProps.is_order_manager || userProps.is_ce_certificates_admin) {
						this.props.setActiveLanguage('se');
						this.props.updateActiveCurrency('sek');
					}
					if (this.props.location.pathname === '/newuser') {
						this.props.history.push('/user');
					}
				} else {
					this.setState({ msgHidden: false });
				}
			})
			.catch((error) => {
				this.setState({ msgHidden: false });
				console.log('error login handleSubmit', error);
			});
	};

	render() {
		const { password, email, forgottenPassword } = this.state;
		return (
			<div className="content">
				<div className="flex-1">
					<Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
						<Grid.Column>
							{forgottenPassword ? (
								<Fragment>
									<Header as="h2" textAlign="center">
										{this.props.translate('forgot_password')}
									</Header>
									<Form size="large" onSubmit={this._handlePasswordSubmit}>
										<Segment stacked>
											<Form.Input
												fluid
												icon="user"
												iconPosition="left"
												placeholder={this.props.translate('emailaddress')}
												name="email"
												value={email}
												onChange={this._handleChange}
											/>
											<Form.Button
												disabled={email !== '' ? false : true}
												content={this.props.translate('send')}
												fluid
												size="large"
												color="red"
												loading={this.state.sending}
											/>
										</Segment>
									</Form>
									<Message color={this.state.msgColor} hidden={this.state.msgHidden}>
										{this.props.translate(this.state.msg)}
									</Message>
								</Fragment>
							) : (
								<Fragment>
									<Header as="h2" textAlign="center">
										{this.props.translate('logon_to_you_accont')}
									</Header>
									<Form size="large" onSubmit={this._handleSubmit}>
										<Segment stacked>
											<Form.Input
												fluid
												icon="user"
												iconPosition="left"
												placeholder={this.props.translate('emailaddress')}
												name="email"
												value={email}
												onChange={this._handleChange}
											/>
											<Form.Input
												fluid
												icon="lock"
												iconPosition="left"
												placeholder={this.props.translate('password')}
												type="password"
												name="password"
												value={password}
												onChange={this._handleChange}
											/>

											<Form.Button
												disabled={email !== '' && password !== '' ? false : true}
												content={this.props.translate('signin')}
												fluid
												size="large"
												color="red"
											/>
											<div className="new-password">
												<span className="show-cursor" onClick={this._forgottenPassword}>
													{this.props.translate('forgot_password')}
												</span>
											</div>
										</Segment>
									</Form>
									<Message error hidden={this.state.msgHidden}>
										{this.props.translate('wrong_email_or_password')}
									</Message>
								</Fragment>
							)}
						</Grid.Column>
					</Grid>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	translate: getTranslate(state.localize)
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ loginUser, getOrderAdminOrders, getOrderAdminUsers, 
		setActiveLanguage, updateActiveCurrency }, dispatch);
};

LoginForm = withLocalize(LoginForm);
LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm);
LoginForm = withRouter(LoginForm);

export default LoginForm;