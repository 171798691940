import React from 'react';
import { Dropdown, Flag } from 'semantic-ui-react';

const LangItem = (props) => {
	let activeFlagName = "gb";
	if (props.currentLanguageId === "en") {
		activeFlagName = "gb";
	} else {
		activeFlagName = props.currentLanguageId;
	}
	return (
		<Dropdown
			item
			simple={props.simple}
			fitted="vertically"
			trigger={
				<span>
					<Flag name={activeFlagName} />
				</span>
			}
			options={props.languagesToSelect}
			value={props.currentLanguageId}
			onChange={props.handleLanguageSelect}
			className="top-menu-item"
		/>
	);
};

export default LangItem;