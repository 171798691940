import md5 from 'md5';

/**
 * Check if user is logged in
 * @param {obj} props {guest, email, id}
 * @returns {bool} true|false
 */
const checkUser = (props) => {
	let { guest, email, id } = props;
	if (guest === md5(email + ':' + id)) {
		return true;
	}
	return false;
};
export default checkUser;
