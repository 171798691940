import {
	GET_ALL_WCCD,
	SELECTED_PRODUCT,
	ADD_PRODUCT_TO_CART,
	CHANGE_QUANTITY_IN_CART,
	REMOVE_ITEM_IN_CART,
	EMPTY_CART,
	SAVE_ORDER_FOR_PRINT,
	IS_LOADING,
	SET_SELECTED_PRODUCT,
	UPDATE_SELECTED_CURRENCY
} from './action-types';
import config from '../config/config';
import axios from 'axios';

export const isLoading = (isLoading) => {
	return {
		type: IS_LOADING,
		payload: {
			isLoading: isLoading
		}
	};
};

export const addProductToCart = (product) => {
	return {
		type: ADD_PRODUCT_TO_CART,
		payload: {
			product
		}
	};
};

export const updateActiveCurrency = (currency) => {
	return {
		type: UPDATE_SELECTED_CURRENCY,
		payload: {
			currency
		}
	}
}

export const changeQuantityInCart = (product) => {
	return {
		type: CHANGE_QUANTITY_IN_CART,
		payload: {
			productId: parseInt(product.productId),
			numOfProducts: parseInt(product.numOfProducts)
		}
	};
};

export const removeItemFromCart = (productId) => {
	return {
		type: REMOVE_ITEM_IN_CART,
		payload: {
			productId
		}
	};
};

export const emptyCart = () => {
	return {
		type: EMPTY_CART,
		payload: {}
	};
};

export const saveOrderForPrint = (orderData) => {
	return {
		type: SAVE_ORDER_FOR_PRINT,
		payload: {
			orderData
		}
	};
};

export const saveAll = (obj) => {
	return {
		type: GET_ALL_WCCD,
		payload: {
			allMachines: obj.allMachines,
			allCountries: obj.allCountries,
			allCurrencies: obj.allCurrencies,
			lastDownloadTime: obj.lastDownloadTime,
			selectedCurrency: obj.selectedCurrency,
			currencyExchangeRate: obj.currencyExchangeRate
		}
	};
};

export const allWccd = (selectedItem) => {
	let newUpdateTime = Date.now();
	return (dispatch) => {
		Promise.all([
			axios.get(config.API_ENDPOINTS.GET_ALL_MACHINE_TYPES),
			axios.get(config.API_ENDPOINTS.GET_COUNTRIES),
			axios.get(config.API_ENDPOINTS.GET_CURRENCIES),
		])
			.then((resp) => {
				let currenciesArray = [];
				resp[2].data.forEach(function(currencyObj) {
					let currencyKey = currencyObj.currency_key;
					let currencyName = currencyObj.currency_name;
					let currencyValue = currencyObj.exchange_rate;
					let currencySymbol = currencyObj.currency_symbol;
					currencyValue = currencyValue.replace(',', '.');
					currenciesArray.push({
						'key': currencyKey,
						'name': currencyName,
						'value': currencyValue,
						'symbol': currencySymbol
					});
				});
				let dispatchObject = {
					allMachines: resp[0].data,
					allCountries: resp[1].data,
					allCurrencies: currenciesArray,
					lastDownloadTime: newUpdateTime
				};
				dispatch(saveAll(dispatchObject));
			})
			.catch((error) => {
				console.log('error allWccd', error);
			});
	};
};

export const setSelectedProduct = (selectedProduct) => {
	return {
		type: SET_SELECTED_PRODUCT,
		payload: {
			selectedProduct
		}
	};
};

export const storeSelectedProduct = (item) => {
	return (dispatch) => {
		Promise.all([
			axios.get(config.API_ENDPOINTS.GET_ALL_PRODUCTS + item.id),
			axios.get(config.API_ENDPOINTS.GET_ALL_CATALOGIES + item.id)
		])
			.then((resp) => {
				let dispatchObject = {
					allProducts: resp[0].data,
					allCategories: resp[1].data
				};
				let product = {
					id: item.id,
					name: JSON.stringify(item.lang_name),
					slug: item.slug,
					img: item.product_catalog_image_url
				};
				dispatch(storeInReduxSelectedProduct(product, dispatchObject));
			})
			.catch((error) => {
				console.log('error storeSelectedProduct', error);
			});
	};
};

export const storeInReduxSelectedProduct = (product, obj) => {
	return {
		type: SELECTED_PRODUCT,
		payload: {
			mainProduct: product,
			allProducts: obj.allProducts,
			allCategories: obj.allCategories
		}
	};
};