import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Translate, getActiveLanguage, getTranslate, withLocalize } from 'react-localize-redux';
import { Icon, Input, Table } from 'semantic-ui-react';

import { changeQuantityInCart, removeItemFromCart } from '../actions/wccd-content';
import currency, { formatCurrency } from '../functions/currency';

class Cart extends Component {
	/** Private functions */

	_changeNumberOfProducts = (event) => {
		let productToChange = {
			productId: event.target.name,
			numOfProducts: event.target.value
		};
		this.props.changeQuantityInCart(productToChange);
	};

	_removeProductInCart = (productId) => {
		const textTranslated = this.props.translate('remove_from_cart');
		let confirmDelete = window.confirm(textTranslated);
		if (confirmDelete === true) {
			this.props.removeItemFromCart(productId);
		}
	};

	_getAllProductsForCart() {
		let allProducts = JSON.parse(JSON.stringify(this.props.products));
		let productsInCart = [];

		for (let i = 0; i < this.props.cart.length; i++) {
			const cartItem = this.props.cart[i];

			let indexCart = allProducts.findIndex((productItem) => {
				return productItem.id === cartItem.productId;
			});
			if (indexCart >= 0) {
				allProducts[indexCart]['numOfProducts'] = cartItem.numOfProducts;
				productsInCart.push(allProducts[indexCart]);
			}
		}
		return productsInCart;
	}

	render() {
		let allProductsInCart = this._getAllProductsForCart();
		let totalCalcPrice = 0;
		let { user, currentLanguage } = this.props;
		const currentLanguageCode = currentLanguage.code;
		let currentCurrencyValue = "";
		if (this.props.currentCurrency !== "") {
			currentCurrencyValue = this.props.currentCurrency;
		}
		let currencySymbol = "";
		this.props.currencies.forEach(function(currencyItem) {
			if (currencyItem.key === currentCurrencyValue) {
				currencySymbol = currencyItem.symbol;
			}
		});

		return (
			<Fragment>
				<Table unstackable selectable basic="very" compact="very" className="table-scrollable-overflow">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								<Translate id="artno" />
							</Table.HeaderCell>
							<Table.HeaderCell>
								<Translate id="product" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								<Translate id="number_of" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								<Translate id="list_price" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								<Translate id="net_price" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								<Translate id="total" />
							</Table.HeaderCell>
							<Table.HeaderCell />
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{allProductsInCart.map((item, index) => {
							
							let { floatPrice } = currency(item.price, currentCurrencyValue, currencySymbol);
							let floatListPrice = floatPrice;
							if (user.discount > 0) {
								floatPrice = floatPrice - floatPrice * (user.discount / 100);
							}
							let calcPrice = floatPrice * item.numOfProducts;
							
							floatListPrice = floatListPrice / this.props.exchangeRate;
							floatPrice = floatPrice / this.props.exchangeRate;
							calcPrice = calcPrice / this.props.exchangeRate;
							totalCalcPrice += calcPrice;
							return (
								<Table.Row key={index}>
									<Table.Cell>{item.sku}</Table.Cell>
									<Table.Cell>{item.lang_name[currentLanguageCode]}</Table.Cell>
									<Table.Cell textAlign="right">
										<Input
											style={{ width: 50 }}
											type="text"
											value={item.numOfProducts || 0}
											name={item.id}
											onChange={this._changeNumberOfProducts}
										/>
									</Table.Cell>
									<Table.Cell textAlign="right">{formatCurrency(floatListPrice, currentCurrencyValue, currencySymbol)}</Table.Cell>
									<Table.Cell textAlign="right">{formatCurrency(floatPrice,currentCurrencyValue, currencySymbol)}</Table.Cell>
									<Table.Cell textAlign="right" className="font-bold">
										{formatCurrency(calcPrice, currentCurrencyValue, currencySymbol)}
									</Table.Cell>
									<Table.Cell textAlign="right">
										<Icon
											link
											name="trash"
											color="red"
											onClick={() => {
												this._removeProductInCart(item.id);
											}}
										/>
									</Table.Cell>
								</Table.Row>
							);
						})}
						<Table.Row>
							<Table.Cell>
							</Table.Cell>
							<Table.Cell colSpan="5" textAlign="right" className="font-bold">
								<Translate id="total" />: {formatCurrency(totalCalcPrice, currentCurrencyValue, currencySymbol)}
							</Table.Cell>
							<Table.Cell />
						</Table.Row>
					</Table.Body>
				</Table>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	cart: state.wccd.cart,
	products: state.wccd.products,
	exchangeRate: state.wccd.currencyExchangeRate,
	currentCurrency: state.wccd.selectedCurrency,
	currencies: state.wccd.currencies,
	user: state.user,
	translate: getTranslate(state.localize),
	currentLanguage: getActiveLanguage(state.localize)
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ changeQuantityInCart, removeItemFromCart }, dispatch);
};

Cart = withLocalize(Cart);
export default connect(mapStateToProps, mapDispatchToProps)(Cart);