import {
	GET_ALL_WCCD,
	SELECTED_PRODUCT,
	ADD_PRODUCT_TO_CART,
	CHANGE_QUANTITY_IN_CART,
	REMOVE_ITEM_IN_CART,
	EMPTY_CART,
	IS_LOADING,
	SET_SELECTED_PRODUCT,
	SAVE_ORDER_FOR_PRINT,
	UPDATE_SELECTED_CURRENCY,
	LOAD_SAVED_DATA_AFTER_UPDATE_RESTART
} from '../actions/action-types';
import updateProducts from '../functions/update-products';
//import config from '../config/config';

// const checkIfExistInCart = (cart, existingProducts, newProducts) => {
// 	cart.forEach((productInCart) => {
// 		let product = newProducts.find((newProduct) => {
// 			return newProduct.id === productInCart.productId;
// 		});

// 		if (product === undefined) {
// 			let addProduct = existingProducts.find((existingProduct) => {
// 				return existingProduct.id === productInCart.productId;
// 			});
// 			newProducts.push(addProduct);
// 		}
// 	});
// 	return newProducts;
// };

// const checkProductsToUpdate = (cart, existingProducts, newProducts) => {
// 	let productsArray = JSON.parse(JSON.stringify(existingProducts));
// 	let cartArray = JSON.parse(JSON.stringify(cart));

// 	for (let i = 0; i < newProducts.length; i++) {
// 		const product = newProducts[i];
// 		let prodIndex = existingProducts.findIndex((existProduct) => {
// 			return existProduct.id === product.id;
// 		});
// 		// console.log('found index id === id', prodIndex);
// 		if (prodIndex < 0) {
// 			// Kolla om produkt finns med samma sku-nr men med olika id-nr,
// 			// i sådanfall ta bort den ur productsArray.
// 			let prodIndexExist = existingProducts.findIndex((existProduct) => {
// 				return existProduct.id !== product.id && existProduct.sku === product.sku;
// 			});
// 			if (prodIndexExist >= 0) {
// 				productsArray[prodIndexExist] = product;

// 				let cartIndex = cart.findIndex((existProduct) => {
// 					return existingProducts[prodIndexExist].id === existProduct.productId;
// 				});
// 				if (cartIndex >= 0) {
// 					cartArray[cartIndex]['productId'] = product.id;
// 				}
// 			} else {
// 				productsArray.push(product);
// 			}
// 		} else {
// 			productsArray[prodIndex] = product;
// 		}
// 	}

// 	return { productsArray, cartArray };
// };

// const checkIfProductExist = (prevState, nextState) => {
// 	// console.log('prevState', prevState);
// 	// console.log('nextState', nextState);
// 	return { prevState, nextState };
// };

export function wccd(
	state = {
		products: [],
		// categories: [],
		categoriesArray: [],
		machineTypes: [],
		countries: {},
		lastUpdate: 0,
		error: null,
		selectedProduct: {},
		isLoading: false,
		isLoaded: false,
		cart: [],
		orderSent: '',
		currencies: {},
		selectedCurrency: '',
		currencyExchangeRate: 0.0
	},
	action
) {
	switch (action.type) {
		case IS_LOADING:
			return { ...state, isLoading: action.payload.isLoading };

		case ADD_PRODUCT_TO_CART:
			//console.log('add to cart', action.payload);
			let newCart = JSON.parse(JSON.stringify(state.cart));
			let addNew = true;
			newCart.forEach((product) => {
				if (product.productId === action.payload.product.productId) {
					product.numOfProducts += action.payload.product.numOfProducts;
					addNew = false;
				}
			});
			if (addNew) {
				return {
					...state,
					cart: [ ...state.cart, action.payload.product ],
					orderSent: ''
				};
			} else {
				return {
					...state,
					cart: newCart,
					orderSent: ''
				};
			}

		case CHANGE_QUANTITY_IN_CART:
			let updateCart = JSON.parse(JSON.stringify(state.cart));
			updateCart.forEach((product) => {
				if (product.productId === action.payload.productId) {
					product.numOfProducts = action.payload.numOfProducts;
				}
			});
			return {
				...state,
				cart: updateCart
			};

		case REMOVE_ITEM_IN_CART:
			let removeFromCart = JSON.parse(JSON.stringify(state.cart));
			removeFromCart.splice(removeFromCart.findIndex((item) => item.productId === action.payload.productId), 1);
			return {
				...state,
				cart: removeFromCart
			};

		case EMPTY_CART:
			let emptyCart = [];
			return {
				...state,
				cart: emptyCart
			};

		case SAVE_ORDER_FOR_PRINT:
			const orderSent = JSON.stringify(action.payload.orderData);
			return {
				...state,
				orderSent: orderSent
			};

		case SET_SELECTED_PRODUCT:
			return {
				...state,
				selectedProduct: action.payload.selectedProduct
			};

		case SELECTED_PRODUCT:
			// console.log('allProducts', JSON.parse(JSON.stringify(action.payload.allProducts)));

			let allProductsChange = updateProducts(state.cart, state.products, action.payload.allProducts);

			return {
				...state,
				selectedProduct: action.payload.mainProduct,
				products: allProductsChange.productsArray,
				cart: allProductsChange.cartArray,
				categoriesArray: {
					...state.categoriesArray,
					[action.payload.mainProduct.slug]: action.payload.allCategories
				},
				isLoading: false,
				isLoaded: true
			};

		case GET_ALL_WCCD:
			let selectedCurrencyUpdateVal;
			let exchangeRateUpdateVal;
			/* let currencySymbolVal; */
			if (state.selectedCurrency === "") {
				selectedCurrencyUpdateVal = "sek";
			} else {
				selectedCurrencyUpdateVal = state.selectedCurrency;
			}
			if (state.currencyExchangeRate === "") {
				exchangeRateUpdateVal = 1.0;
			} else {
				exchangeRateUpdateVal = state.currencyExchangeRate;
			}
			if (exchangeRateUpdateVal === 0) {
				exchangeRateUpdateVal = 1.0;
			}
			/* if (state.currencySymbol === "") {
				currencySymbolVal = state.currencySymbol;
			} */

			return {
				...state,
				machineTypes: action.payload.allMachines,
				countries: action.payload.allCountries,
				currencies: action.payload.allCurrencies,
				lastUpdate: action.payload.lastDownloadTime,
				selectedCurrency: selectedCurrencyUpdateVal,
				currencyExchangeRate: exchangeRateUpdateVal
			};

		case UPDATE_SELECTED_CURRENCY:
			let exchangeRateValue = parseFloat(state.currencies.find(function(currencyItem) {
				return currencyItem.key === action.payload.currency;
			}).value);
			return {
				...state,
				selectedCurrency: action.payload.currency,
				currencyExchangeRate: exchangeRateValue
			}

		case LOAD_SAVED_DATA_AFTER_UPDATE_RESTART:
			return {
				...state,
				cart: action.payload.cartData,
				products: action.payload.saveProductItems
			}

		default:
			return state;
	}
}
