import React, { Component, Fragment } from "react";
import { Accordion, Icon } from "semantic-ui-react";

class ListProducts extends Component {
  /** Private functions */

  _selectedMainProduct = (item) => {
    this.props.selectedMainProduct(item);
  };

  _getMainProducts(item) {
    let returnJSX = [];
    // var loopCount = 0;
    for (let i = 0; i < item.length; i++) {
      const product = item[i];
      var productShowForLangs = [];
      if (product.showForLang) {
        productShowForLangs = product.showForLang;
      }
      var productIsHiddenForEU = false;
      if (!product.showForLang.includes("se")) {
        productIsHiddenForEU = true;
      }

      if (productShowForLangs.includes(this.props.selectedLanguageCode)) {
        returnJSX.push(
          <div
            className="flex-shrink-1 text-align-center show-cursor product-box"
            key={product.id}
            onClick={() => this._selectedMainProduct(product)}
          >
            <img
              className="main-category-image "
              src={product.product_catalog_image_url}
              alt=""
            />
            <br />
            {product.lang_name[this.props.selectedLanguageCode]}
            {productIsHiddenForEU && (
              <span className="not-sold-in-eu-product-box-text">
                (Not sold in the EU)
              </span>
            )}
          </div>
        );
      }
      /*if (loopCount == 3) {
				loopCount = 0;
				//TODO : fix
				returnJSX.push(
					<br />
				);
			}
			loopCount++; */
    }
    return returnJSX;
  }

  render() {
    const { activeIndex } = this.props;

    return (
      <Accordion fluid styled>
        {this.props.childItem.map((childItem, index) => {
          if (childItem.product_categories.length > 0) {
            let mainProducts = this._getMainProducts(
              childItem.product_categories
            );

            if (mainProducts.length < 1) {
              return null;
            }

            return (
              <Fragment key={index}>
                <Accordion.Title
                  key={index}
                  active={activeIndex === childItem.id}
                  index={childItem.id}
                  onClick={this.props.selectedIndex}
                >
                  <Icon name="dropdown" />
                  {childItem.lang_name[this.props.selectedLanguageCode]}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === childItem.id}>
                  <div className="products-container">{mainProducts}&nbsp;</div>
                </Accordion.Content>
              </Fragment>
            );
          } else {
            /* return (
              <Fragment key={index}>
                <Accordion.Title
                  key={index}
                  active={activeIndex === childItem.id}
                  index={childItem.id}
                  onClick={this.props.selectedIndex}
                >
                  <Icon name="dropdown" />
                  {childItem.lang_name[this.props.selectedLanguageCode]}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === childItem.id}>
                  <div>
                    <Translate id="no_products_in_category" />
                  </div>
                </Accordion.Content>
              </Fragment>
            ); */
            return null;
          }
        })}
      </Accordion>
    );
  }
}

export default ListProducts;
