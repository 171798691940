import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, getActiveLanguage } from 'react-localize-redux';
import { Table } from 'semantic-ui-react';

import currency, { formatCurrency } from '../functions/currency';

class CartSummery extends Component {
	/** Private functions */

	_getSentOrder = () => {
		if (this.props.orderSent !== '') {
			let orderSent = JSON.parse(this.props.orderSent);
			let cartArray = orderSent.line_items;
			let cart = cartArray.map((item) => {
				return {
					productId: item.product_id,
					numOfProducts: item.quantity
				};
			});
			return cart;

		} else {
			return null;
		}
	};

	_getAllProductsForCart(cart) {
		let allProducts = JSON.parse(JSON.stringify(this.props.products));
		let productsInCart = [];

		for (let i = 0; i < cart.length; i++) {
			const cartItem = cart[i];

			let indexCart = allProducts.findIndex((productItem) => {
				return productItem.id === cartItem.productId;
			});
			if (indexCart >= 0) {
				allProducts[indexCart]['numOfProducts'] = cartItem.numOfProducts;
				productsInCart.push(allProducts[indexCart]);
			}
		}
		return productsInCart;
	}

	render() {
		let cartObjects = this._getSentOrder() !== null ? this._getSentOrder() : this.props.cart;
		let allProductsInCart = [];
		allProductsInCart = this._getAllProductsForCart(cartObjects);
		let totalCalcPrice = 0;
		let { user, currentLanguage, orderObject } = this.props;
		let currentLanguageCode = currentLanguage.code;
		let mainCustomerDiscount = orderObject ? orderObject.main_customer_discount: undefined;
		let currentCurrencyValue = this.props.currentCurrency;
		let orderCurrency = this.props.orderCurrency;
		let exchangeRate = this.props.exchangeRate;
		let currencySymbol = "";
		let orderCurrencySymbol = "";
		let allTotalCost = 0;

		if (mainCustomerDiscount !== undefined && !isNaN(mainCustomerDiscount)) {
			mainCustomerDiscount = parseFloat(mainCustomerDiscount.replace(",", "."));
		} else {
			mainCustomerDiscount = 0;
		}

		if (allProductsInCart.length < 1 && this.props.orderArticlesArray !== undefined) {
			this.props.orderArticlesArray.forEach(orderItem => {
				allProductsInCart.push({
					'numOfProducts': orderItem.quantity,
					'sku': orderItem.sku,
					'itemLangName': orderItem.name,
					'listPrice': orderItem.listPrice,
					'netPrice': orderItem.netPrice,
					'totalPrice': orderItem.totalPrice,
					'price': ''
				});
			});
		}

		this.props.currencies.forEach(function(currencyItem) {
			if (currencyItem.key === currentCurrencyValue) {
				currencySymbol = currencyItem.symbol;
			}
			if (currencyItem.key === orderCurrency) {
				orderCurrencySymbol = currencyItem.symbol;
				exchangeRate = parseFloat(currencyItem.value.replace(",", "."));
			}
		});

		return (
			<Fragment>
				<Table unstackable selectable basic="very" compact="very" className="table-scrollable-overflow">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								<Translate id="artno" />
							</Table.HeaderCell>
							<Table.HeaderCell>
								<Translate id="product" />
							</Table.HeaderCell>
							<Table.HeaderCell>
								<Translate id="number_of" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								<Translate id="list_price" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								<Translate id="net_price" />
							</Table.HeaderCell>
							<Table.HeaderCell textAlign="right">
								<Translate id="total" />
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{allProductsInCart.map((item, index) => {
							item.listPrice = (isNaN(item.price) || item.price === "" || item.price === undefined)
								? (isNaN(item.listPrice) || item.listPrice === "" || item.listPrice === undefined)
									? item.regular_price
									: item.listPrice
									: item.price;
							let { floatPrice } = currency(item.listPrice, currencySymbol);
							let floatListPrice = floatPrice;
							let calcPrice = 0;
							if(!isNaN(floatPrice)) {
								if (user.discount > 0 || mainCustomerDiscount > 0) {
									floatPrice = mainCustomerDiscount > 0
										? floatPrice - floatPrice * (mainCustomerDiscount / 100)
										: user.discount > 0
										? floatPrice - floatPrice * (user.discount / 100)
										: floatPrice;
								}

								calcPrice = floatPrice * item.numOfProducts;
								floatListPrice = floatListPrice / exchangeRate;
								floatPrice = floatPrice / exchangeRate;
								calcPrice = calcPrice / exchangeRate;
								totalCalcPrice += calcPrice;
							}
							let itemName = "";
							let listPriceText = "";
							let netPriceText = "";
							let totalPriceText = "";
							let totalPrice = item.totalPrice / exchangeRate;
							allTotalCost += !isNaN(parseFloat(totalPrice)) ? parseFloat(totalPrice): 0;

							if (this.props.orderArticlesArray.length < 1) {
								itemName = item.lang_name[currentLanguageCode]
								listPriceText = formatCurrency(floatListPrice, currentCurrencyValue, currencySymbol);
								netPriceText = formatCurrency(floatPrice, currentCurrencyValue, currencySymbol);
								totalPriceText = formatCurrency(calcPrice, currentCurrencyValue, currencySymbol);
							} else {
								itemName = item.itemLangName;
								currentCurrencyValue = orderCurrency ? orderCurrency: "sek";
								currencySymbol = orderCurrencySymbol ? orderCurrencySymbol: "kr";

								listPriceText = formatCurrency(floatListPrice, currentCurrencyValue, currencySymbol);
								netPriceText = formatCurrency(floatPrice, currentCurrencyValue, currencySymbol);
								totalPriceText = formatCurrency(calcPrice, currentCurrencyValue, currencySymbol);
							}
							return (
								<Table.Row key={index}>
									<Table.Cell>{item.sku}</Table.Cell>
									<Table.Cell>{itemName}</Table.Cell>
									<Table.Cell>{item.numOfProducts}</Table.Cell>
									<Table.Cell textAlign="right">{listPriceText}</Table.Cell>
									<Table.Cell textAlign="right">{netPriceText}</Table.Cell>
									<Table.Cell textAlign="right" className="font-bold">{totalPriceText}</Table.Cell>
								</Table.Row>
							);
						})}
						<Table.Row>
							{ this.props.orderArticlesArray.length < 1 &&
							<Table.Cell colSpan="6" textAlign="right" className="font-bold">
								<Translate id="total" />: {formatCurrency(totalCalcPrice, currentCurrencyValue, currencySymbol)}
							</Table.Cell>
							}
							{this.props.orderArticlesArray.length > 1 &&
							<Table.Cell colSpan="6" textAlign="right" className="font-bold">
								<Translate id="total" />: {formatCurrency(allTotalCost, currentCurrencyValue, currencySymbol)}
							</Table.Cell>
							}
						</Table.Row>
					</Table.Body>
				</Table>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	cart: state.wccd.cart,
	products: state.wccd.products,
	exchangeRate: state.wccd.currencyExchangeRate,
	currentCurrency: state.wccd.selectedCurrency,
	user: state.user,
	orderSent: state.wccd.orderSent,
	currencies: state.wccd.currencies,
	currentLanguage: getActiveLanguage(state.localize),
});

export default connect(mapStateToProps)(CartSummery);