import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Segment, Button, Icon, Dimmer, Loader } from 'semantic-ui-react';
import withRouter from 'react-router/withRouter';
import { getTranslate, Translate } from 'react-localize-redux';
import config from '../config/config';
import { Link } from 'react-router-dom';

import Summary from '../components/checkout-summary';

class OrderAdminOrderDetailsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoadingScreen: false,
            orderIdValue: "",
            orderItem: null
        };
    }

    _printPage = () => {
		window.print();
    };

    componentDidMount() {
        let orderId = this.props.match.params.orderId;
        const urlPath = config.API_ENDPOINTS.ORDER_ADMIN_GET_SINGLE_ORDER + "/" + orderId + "?gId=" + this.props.user.gId;
        this.setState({
            orderIdValue: orderId,
            showLoadingScreen: true
        });

        Promise.all([
			axios.get(urlPath)
		])
        .then((resp) => {
            const orderResponse = resp[0].data;
            this.setState({
                orderItem: orderResponse,
                showLoadingScreen: false
            });
        })
		.catch((error) => {
			console.log('error allWccd', error);
		});
    }
    
    render() {
        return (
            <Fragment>
                <Link to="/order-admin/orders">&larr; {this.props.translation("orderadmin_go_back")}</Link>
                <Segment>
                <Dimmer active={this.state.showLoadingScreen} style={{"zIndex": "1"}}  inverted><Loader /></Dimmer>
                    {this.state.orderItem &&
                        <Summary
                            hideHeader={true}
                            viewOrderItem={this.state.orderItem}
                            orderIdValue={this.state.orderIdValue}
                            historyObject={this.props.history}
                        />
                    }
                </Segment>
                {this.state.orderItem &&
                    <div className="no-print">
                        <Button floated="right" onClick={this._printPage}>
                            <Icon name="print" /> <Translate id="print_order" />
                        </Button>
                    </div> 
                }
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
	user: state.user,
    translation: getTranslate(state.localize),
});

OrderAdminOrderDetailsPage = connect(mapStateToProps)(OrderAdminOrderDetailsPage);
OrderAdminOrderDetailsPage = withRouter(OrderAdminOrderDetailsPage);

export default OrderAdminOrderDetailsPage;