import React from 'react';
import { Menu, Image } from 'semantic-ui-react';

const LogoItem = (props) => {
	let widthValue = 220;
	let marginLeftValue = "0px";
	let logoPath = props.themeLocation + '/images/swepac-logo-new.png';
	if (props.compact) {	
		widthValue = 100;
		// logoPath = props.themeLocation + '/images/swepac-logo.png';
		marginLeftValue = "-10px";
	}
	return (
		<Menu.Item fitted={props.fitted} className="margin-right-20">
			<Image
				src={logoPath}
				style={{ marginRight: 0, width: widthValue, marginLeft: marginLeftValue }}
				onClick={() => {
					props.gotoPage('/');
				}}
				className="show-cursor"
			/>
		</Menu.Item>
	);
};

export default LogoItem;