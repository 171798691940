import React, { Component, Fragment } from "react";
import { Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

class ServiceMenuTreeListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nodeData: {},
      menuDisplay: { maxHeight: 0 },
      menuOpen: false,
      selectedNodesId: [],
    };
  }

  /** Private functions */

  _clickLinkLastItem = (path, postTitle, postContent, postId) => {
    // this.props.history.push('/category/service-info-' + path);
    this.props.history.push({
      pathname: "/category/service-info-" + path,
      state: {
        serviceInfoPostName: postTitle,
        serviceInfoPostContent: postContent,
        serviceInfoPostId: postId,
      },
    });

    this.props.handleCategoryClick(path);
  };

  _clickLinkParentItem = (slug, id, postTitle, postContent, postId) => {
    let selectedNodeId = this.state.selectedNodesId.indexOf(id);
    if (selectedNodeId < 0) {
      this.setState({ selectedNodesId: [...this.state.selectedNodesId, id] });
    }
    this._clickLinkLastItem(slug, postTitle, postContent, postId);
  };

  _clickCaretParentItem = (slug, id, postTitle, postContent, postId) => {
    let selectedNodeId = this.state.selectedNodesId.indexOf(id);
    if (selectedNodeId < 0) {
      this.setState({ selectedNodesId: [...this.state.selectedNodesId, id] });
      this._clickLinkLastItem(slug, postTitle, postContent, postId);
    } else {
      let newSelectedNodesArray = Array.from(this.state.selectedNodesId);
      newSelectedNodesArray.splice(selectedNodeId, 1);
      this.setState({ selectedNodesId: newSelectedNodesArray });
    }
  };

  /**
   * _getAllChildren
   * @param {object} item Parent kategori
   * @returns {array} Returnerar en JSX-array med ul och li med children nodes för montering
   */
  _getAllChildren = (item) => {
    const { selectedNodesId } = this.state;
    let returnJSX = [];

    let index = 0;
    item.forEach((element) => {
      let mainItem = [];

      let childItems = [];
      if (element.children.length > 0) {
        let childrenClass = "hidden-node";
        let caretIcon = "caret down";
        let classSelectedNode = "tree-not-selected show-cursor";
        if (selectedNodesId.indexOf(element.id) >= 0) {
          childrenClass = "";
          caretIcon = "caret up";
          classSelectedNode = "tree-selected show-cursor";
        }
        mainItem.push(
          <Icon
            key={index}
            link
            name={caretIcon}
            onClick={() => {
              this._clickCaretParentItem(
                element.id,
                element.id,
                element.name,
                element.post_content,
                element.post_id
              );
            }}
          />
        );
        index++;
        mainItem.push(
          <span
            className={classSelectedNode}
            key={index}
            onClick={() => {
              this._clickLinkParentItem(
                element.id,
                element.id,
                element.name,
                element.post_content,
                element.post_id
              );
            }}
          >
            {/* {element.pos_num}. {element.lang_name[languageCode]} */}
            {element.name}
          </span>
        );
        index++;
        childItems.push(
          <ul key={index} className={childrenClass}>
            {this._getAllChildren(element.children)}
          </ul>
        );
        index++;
        returnJSX.push(
          <li key={element.id}>
            {mainItem}
            {childItems}
          </li>
        );
        index++;
      } else {
        mainItem.push(
          <span className="show-cursor margin-left-tiny" key={index}>
            {/* {element.lang_name[languageCode]} */}
            {element.name}
          </span>
        );
        index++;
        returnJSX.push(
          <li
            key={element.id}
            onClick={() =>
              this._clickLinkLastItem(
                element.id,
                element.name,
                element.post_content,
                element.post_id
              )
            }
            className={
              this.props.activeNode === element.id
                ? "tree-active tree-view-li"
                : "tree-view-li"
            }
          >
            {/* {element.pos_num}. {mainItem} */}
            {mainItem}
          </li>
        );
        index++;
      }
    });
    return returnJSX;
  };

  _buildTree(item) {
    const { selectedNodesId } = this.state;

    let returnJSX = [];
    let index = 0;

    /* item.children.sort(function(a, b) {
			return a.pos_num - b.pos_num;
		}); */

    if (item.children.length > 0) {
      let childrenClass = "hidden-node";
      let caretIcon = "caret down";
      let classSelectedNode = "tree-not-selected show-cursor";
      if (selectedNodesId.indexOf(item.id) >= 0) {
        childrenClass = "";
        caretIcon = "caret up";
        classSelectedNode = "tree-selected show-cursor";
      }
      returnJSX.push(
        <Icon
          key={index}
          link
          name={caretIcon}
          style={{ fontSize: 16 }}
          onClick={() => {
            this._clickCaretParentItem(
              item.id,
              item.id,
              item.name,
              item.post_content,
              item.post_id
            );
          }}
        />
      );
      index++;
      returnJSX.push(
        <span
          className={classSelectedNode}
          key={index}
          onClick={() => {
            this._clickLinkParentItem(
              item.id,
              item.id,
              item.name,
              item.post_content,
              item.post_id
            );
          }}
        >
          {/* {item.pos_num}. {item.lang_name[languageCode]} */}
          {item.name}
        </span>
      );
      index++;

      returnJSX.push(
        <ul key={index} className={childrenClass}>
          {this._getAllChildren(item.children)}
        </ul>
      );
      return <li key={item.id}>{returnJSX}</li>;
    } else {
      returnJSX.push(
        <Fragment key={index}>
          {" "}
          {/* {item.pos_num}. <span className="show-cursor margin-left-tiny">{item.lang_name[languageCode]}</span> */}
          {item.pos_num}.{" "}
          <span className="show-cursor margin-left-tiny">{item.name}</span>
        </Fragment>
      );
      return (
        <li
          onClick={() =>
            this._clickLinkLastItem(
              item.id,
              item.name,
              item.post_content,
              item.post_id
            )
          }
          key={item.id}
          className={
            this.props.activeNode === item.id
              ? "tree-active tree-view-li"
              : "tree-view-li"
          }
        >
          <Fragment>{returnJSX}</Fragment>
        </li>
      );
    }
  }
  render() {
    return <Fragment>{this._buildTree(this.props.props)}</Fragment>;
  }
}

export default withRouter(ServiceMenuTreeListItem);
