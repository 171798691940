import React, { Fragment } from "react";
import { Dropdown } from "semantic-ui-react";
import { Translate } from "react-localize-redux";

import CurrencyItem from "./currency-item";

const UserDropdown = (props) => {
  const userIsOrderMan = props.userIsOrderMan;
  const userIsCeAdmin = props.userIsCeAdmin;
  return (
    <Dropdown
      item
      simple={props.simple}
      fitted="vertically"
      trigger={
        <span>
          <Translate id="account" />
        </span>
      }
      className="top-menu-item"
    >
      {props.userExist ? (
        <Dropdown.Menu>
          {!userIsOrderMan && !userIsCeAdmin && (
            <Fragment>
              <CurrencyItem
                currenciesToSelect={props.currenciesToSelect}
                currentCurrencyId={props.currentCurrencyId}
                handleCurrencySelect={props.handleCurrencySelect}
                simple={props.simple}
              />
              <Dropdown.Item
                icon="setting"
                text={props.doTranslate("my_page")}
                onClick={() => {
                  props.gotoPage("/user");
                }}
              />
            </Fragment>
          )}
          <Dropdown.Item
            icon="sign-out"
            text={props.doTranslate("signout")}
            onClick={props.gotoLogout}
          />
          {!userIsOrderMan && !userIsCeAdmin && (
            <Fragment>
              <Dropdown.Item
                icon="info"
                text={props.doTranslate("info")}
                onClick={() => {
                  props.gotoPage("/info");
                }}
              />
            </Fragment>
          )}
        </Dropdown.Menu>
      ) : (
        <Dropdown.Menu>
          <Dropdown.Item
            icon="sign in"
            text={props.doTranslate("logon_to_you_accont")}
            onClick={props.toogleLogin}
          />
          <Dropdown.Item
            icon="user"
            text={props.doTranslate("create_new_account")}
            onClick={() => {
              props.gotoPage("/newuser");
            }}
          />
          <CurrencyItem
            currenciesToSelect={props.currenciesToSelect}
            currentCurrencyId={props.currentCurrencyId}
            handleCurrencySelect={props.handleCurrencySelect}
            simple={props.simple}
          />
          <Dropdown.Item
            icon="info"
            text={props.doTranslate("info")}
            onClick={() => {
              props.gotoPage("/info");
            }}
          />
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};

export default UserDropdown;
