import React, { Component, Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize, getTranslate } from 'react-localize-redux';
import { Form, Grid, Table } from 'semantic-ui-react';

import { updateCheckOutUser, updateCheckOutUserBilling, updateCheckOutUserDelivery } from '../actions/user-action';

class CheckoutUser extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modalOpen: false,
			id: 0,
			deliveryTime: props.user.deliveryTime !== '' ? props.user.deliveryTime : '',
			backorder_split_delivery: props.user.backorder_split_delivery,
			deliveryAdress: props.user.delivery ? props.user.delivery : {},
			choosenDeliveryAddressId: props.user.delivery.id
		};
	}

	/** Private functions */

	_handleChange = (e, { name, value }) => {
		this.props.updateCheckOutUser(name, value);
	};

	_handleBillingChange = (e, { name, value }) => {
		this.props.updateCheckOutUserBilling(name, value);
	};

	_handleBillingCountry = (e, { value }) => {
		this.props.updateCheckOutUserBilling('billing_country', value);
	};

	_handleAddressChange = (e, { name, value }) => {
		let address = this.props.user.deliveryAddresses.find((address) => {
			return address.id === value;
		});
		this.props.updateCheckOutUserDelivery(address);
		this.setState({ choosenDeliveryAddressId: value, deliveryAdress: address });
	};

	_getDeliveryAddresses() {
		let deliveryAddresses = [];
		let addresses = this.props.user.deliveryAddresses;
		for (let i = 0; i < addresses.length; i++) {
			const address = addresses[i];
			let text = address.street + ', ' + address.postal_area;
			let className = '';
			if (address.id === this.state.choosenDeliveryAddressId) {
				className = 'font-bold-important';
			}
			deliveryAddresses.push({
				key: i,
				text: text,
				value: address.id,
				className: className
			});
		}

		return deliveryAddresses;
	}

	render() {
		let addresses = this._getDeliveryAddresses();
		const user = this.props.user;
		const { deliveryAdress } = this.state;
		const { translate } = this.props;
		let getIndexOfSelectedAddress = addresses.findIndex((address) => {
			return address.value === this.state.choosenDeliveryAddressId;
		});

		return (
			<Fragment>
				<div className="">
					<div style={{ flex: 1 }}>
						<h2>{translate('customer_data_delivery_address')}</h2>
						<br />
						<Form>
							<Grid divided="vertically" relaxed stackable>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Form.Input
											label={translate('your_order_id')}
											placeholder={translate('your_order_id')}
											name="customerOrderNum"
											required
											value={user.customerOrderNum}
											onChange={this._handleChange}
										/>
										<Form.Group widths="equal">
											<Form.Input
												label={translate('ref_name')}
												placeholder={translate('user_firstname')}
												name="firstname"
												required
												value={user.firstname}
												onChange={this._handleChange}
											/>
											<Form.Input
												label="&nbsp;"
												placeholder={translate('user_lastname')}
												name="lastname"
												value={user.lastname}
												onChange={this._handleChange}
											/>
										</Form.Group>
										<Form.Input
											label={translate('user_phone')}
											placeholder={translate('user_phone')}
											name="phone"
											required
											value={user.phone}
											onChange={this._handleChange}
										/>
										<Form.Input
											label={translate('user_email')}
											placeholder={translate('user_email')}
											name="email"
											required
											value={user.email}
											onChange={this._handleChange}
										/>
										{/*<Form.Field>
											<label>
												{translate('user_email')}{' '}
												<span style={{ fontWeight: 'normal' }}>
													({translate('user_email_no_change')})
												</span>
											</label>
											<input
												placeholder={translate('user_email')}
												readOnly
												disabled
												value={user.email}
											/>
										</Form.Field>*/}
									</Grid.Column>
									<Grid.Column>
										<Form.Group grouped>
											<label>{translate('selected_backorder')}</label>{' '}
											<span className="mandatory" style={{ fontSize: 14 }}>
												*
											</span>
											<Form.Radio
												label={translate('backorder_complete')}
												value={2}
												checked={user.backorder_split_delivery === 2}
												name="backorder_split_delivery"
												onChange={this._handleChange}
											/>
											<Form.Radio
												label={translate('backorder_split')}
												value={1}
												checked={user.backorder_split_delivery === 1}
												name="backorder_split_delivery"
												onChange={this._handleChange}
											/>
										</Form.Group>
										<Form.Input
											label={translate('shipping_label')}
											placeholder={translate('shipping_label')}
											name="parcelId"
											value={user.parcelId}
											onChange={this._handleChange}
										/>
										<Form.TextArea
											label={translate('note')}
											name="notes"
											value={user.notes}
											maxLength="70"
											onChange={this._handleChange}
										/>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row columns={2}>
									<Grid.Column>
										<Table stackable>
											<Table.Header>
												<Table.Row>
													<Table.HeaderCell colSpan="2">
														{translate('main_customer')}
													</Table.HeaderCell>
												</Table.Row>
											</Table.Header>
											<Table.Body>
												<Table.Row>
													<Table.Cell width="4">{translate('main_customer_id')}</Table.Cell>
													<Table.Cell width="12">{user.mainCustomerId}</Table.Cell>
												</Table.Row>
												<Table.Row>
													<Table.Cell width="4">{translate('main_customer_name')}</Table.Cell>
													<Table.Cell width="12">{user.mainCompany}</Table.Cell>
												</Table.Row>
											</Table.Body>
										</Table>
									</Grid.Column>
									<Grid.Column>
										<h3>
											{translate('delivery_address')}{' '}
											<span className="mandatory" style={{ fontSize: 14 }}>
												*
											</span>
										</h3>
										<Form.Select
											fluid
											label={translate('select_delivery_address')}
											options={addresses}
											placeholder={translate('select_delivery_address')}
											name="deliveryAddresses"
											value={addresses[getIndexOfSelectedAddress].value}
											onChange={this._handleAddressChange}
										/>
										{Object.keys(deliveryAdress).length === 0 &&
										deliveryAdress.constructor === Object ? (
											<Fragment />
										) : (
											<div>
												<hr />
												<h4>{translate('selected_delivery_address')}</h4>
												{deliveryAdress.street}
												<br />
												{deliveryAdress.street_2 !== '' && (
													<Fragment>
														{deliveryAdress.street_2} <br />
													</Fragment>
												)}
												{deliveryAdress.zip_code
													.replace(/(\d{3})/g, '$1 ')
													.replace(/(^\s+|\s+$)/, '')}
												&nbsp;&nbsp;
												{deliveryAdress.postal_area.toUpperCase()} <br />
												{deliveryAdress.country}
											</div>
										)}
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>
					</div>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user.checkOutUser,
	countries: state.wccd.countries,
	translate: getTranslate(state.localize)
});

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({ updateCheckOutUser, updateCheckOutUserBilling, updateCheckOutUserDelivery }, dispatch);
};

CheckoutUser = withLocalize(CheckoutUser);
export default connect(mapStateToProps, mapDispatchToProps)(CheckoutUser);