const updatedCart = (prevCart, newCart) => {
	//console.log('Do loop cart', { prevCart, newCart });
	if (newCart.length <= 0) {
		return null;
	} else if (prevCart.length < newCart.length) {
		//console.log('carts är olika', newCart.length);
		let newCartItem = newCart[newCart.length - 1];
		return {
			id: newCartItem.productId,
			numOfProducts: newCartItem.numOfProducts
		};
	} else if (prevCart.length > newCart.length) {
		return null;
	} else {
		for (let i = 0; i < prevCart.length; i++) {
			const item = prevCart[i];
			let addedProduct = newCart.find((products) => {
				return products.productId === item.productId;
			});
			if (addedProduct !== {}) {
				//console.log('found item', addedProduct);
				if (addedProduct.numOfProducts !== item.numOfProducts) {
					// console.log('prev item', item);
					// console.log('found item', addedProduct);
					return {
						id: item.productId,
						numOfProducts: addedProduct.numOfProducts - item.numOfProducts
					};
				}
			}
		}
	}

	return null;
};

export default updatedCart;
