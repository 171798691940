import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router/withRouter';
import { withLocalize, getTranslate } from 'react-localize-redux';

class NewUserSuccess extends Component {    
    render() {
        return (
            <div>
                <h2>{this.props.translate('registration_was_successful')}</h2>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    translate: getTranslate(state.localize)
});

NewUserSuccess = withLocalize(NewUserSuccess);
NewUserSuccess = connect(mapStateToProps)(NewUserSuccess);
NewUserSuccess = withRouter(NewUserSuccess);

export default NewUserSuccess;