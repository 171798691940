import {
  UPDATE_USER,
  REFRESH_USER,
  LOGIN_USER,
  LOGOUT_USER,
  UPDATE_CHECKOUT_USER,
  UPDATE_CHECKOUT_USER_BILLING,
  UPDATE_CHECKOUT_USER_DELIVERY,
  BATCH_UPDATE_USER,
  EMPTY_CHECKOUT_USER,
  SAVE_ORDER_ADMIN_ORDERS,
  ORDER_ADMIN_UPDATE_ORDER,
  ORDER_ADMIN_GET_USERS,
  ORDER_ADMIN_USERS_ACTIVE_TAB,
  ORDER_ADMIN_DISPLAY_NONEXPORT_ORDERS,
  ORDER_ADMIN_ORDERS_ACTIVE_TAB,
  ORDER_ADMIN_ORDERS_CHANGE_COUNTRIES,
  UPDATE_USER_PHONE,
} from "./action-types";
import config from "../config/config";
import axios from "axios";

export const updateUser = (user) => {
  return {
    type: UPDATE_USER,
    payload: {
      name: user.name,
      value: user.value,
    },
  };
};

export const batchUpdateUser = (user) => {
  return {
    type: BATCH_UPDATE_USER,
    payload: {
      user,
    },
  };
};

export const updateCheckOutUser = (name, value) => {
  return {
    type: UPDATE_CHECKOUT_USER,
    payload: {
      name,
      value,
    },
  };
};

export const emptyCheckOutUser = () => {
  return {
    type: EMPTY_CHECKOUT_USER,
    payload: {},
  };
};

export const updateUserPhone = (newPhoneNumber) => {
  return {
    type: UPDATE_USER_PHONE,
    payload: {
      newPhoneNumber,
    },
  };
};

export const updateCheckOutUserBilling = (name, value) => {
  return {
    type: UPDATE_CHECKOUT_USER_BILLING,
    payload: {
      name,
      value,
    },
  };
};

export const updateCheckOutUserDelivery = (address) => {
  return {
    type: UPDATE_CHECKOUT_USER_DELIVERY,
    payload: {
      address,
    },
  };
};

export const loginUser = (user) => {
  return {
    type: LOGIN_USER,
    payload: {
      user,
    },
  };
};

export const saveOrderAdminOrders = (obj) => {
  return {
    type: SAVE_ORDER_ADMIN_ORDERS,
    payload: {
      orderAdminOrders: obj.orderAdminOrders,
    },
  };
};

export const saveOrderAdminUsers = (obj) => {
  return {
    type: ORDER_ADMIN_GET_USERS,
    payload: {
      orderAdminUsers: obj.orderAdminUsers,
    },
  };
};

export const orderAdminUpdateOrderObject = (obj) => {
  return {
    type: ORDER_ADMIN_UPDATE_ORDER,
    payload: {
      orderId: obj.orderId,
      newStatus: obj.newStatus,
      shouldDelete: obj.shouldDelete,
    },
  };
};

export const getOrderAdminOrders = (user) => {
  const urlPath =
    config.API_ENDPOINTS.ORDER_ADMIN_GET_ORDERS + "?gId=" + user.gId;

  return (dispatch) => {
    return Promise.all([axios.get(urlPath)])
      .then((resp) => {
        const ordersResponse = resp[0].data;
        const dispatchObject = {
          orderAdminOrders: ordersResponse,
        };
        dispatch(saveOrderAdminOrders(dispatchObject));
      })
      .catch((error) => {
        console.log("error allWccd", error);
      });
  };
};

export const orderAdminUsersSetActiveTab = (activeTabIndex) => {
  return {
    type: ORDER_ADMIN_USERS_ACTIVE_TAB,
    payload: {
      activeTabIndex: activeTabIndex,
    },
  };
};

export const orderAdminOrdersSetActiveTab = (activeTabIndex) => {
  return {
    type: ORDER_ADMIN_ORDERS_ACTIVE_TAB,
    payload: {
      activeTabIndex: activeTabIndex,
    },
  };
};
export const orderAdminSetActiveCountries = (newSelectedCountries) => {
  return {
    type: ORDER_ADMIN_ORDERS_CHANGE_COUNTRIES,
    payload: {
      newSelectedCountries: newSelectedCountries,
    },
  };
};

export const orderAdminSetDisplayNonExportOrders = (
  displayNonExportOrdersValue
) => {
  return {
    type: ORDER_ADMIN_DISPLAY_NONEXPORT_ORDERS,
    payload: {
      displayNonExportOrders: displayNonExportOrdersValue,
    },
  };
};

export const getOrderAdminUsers = (user) => {
  const urlPath =
    config.API_ENDPOINTS.ORDER_ADMIN_GET_USERS + "?gId=" + user.gId;

  return (dispatch) => {
    return Promise.all([axios.get(urlPath)])
      .then((resp) => {
        const usersResponse = resp[0].data;
        const dispatchObject = {
          orderAdminUsers: usersResponse,
        };
        dispatch(saveOrderAdminUsers(dispatchObject));
      })
      .catch((error) => {
        console.log("error allWccd", error);
      });
  };
};

export const orderAdminExportOrders = (
  user,
  exportFunction,
  selectedCountries
) => {
  let selectedCountriesString = selectedCountries.join().toLowerCase();
  const urlPath =
    config.API_ENDPOINTS.ORDER_ADMIN_EXPORT_ORDERS +
    "?gId=" +
    user.gId +
    "&exportFunction=" +
    exportFunction +
    "&selectedCountries=" +
    selectedCountriesString;

  return () => {
    return Promise.all([axios.get(urlPath)])
      .then((resp) => {
        if (resp[0].data) {
          // var data = new Blob(['\ufeff'], {type: 'application/text'});
          let fileName =
            "orders-export-" + Math.floor(Date.now() / 1000) + ".txt";
          let tempLink = document.createElement("a");
          tempLink.href = resp[0].data.file_url;
          tempLink.setAttribute("download", fileName);
          tempLink.click();
        }
      })
      .catch((error) => {
        console.log("error allWccd", error);
      });
  };
};

export const orderAdminUpdateUser = (
  postData,
  orderAdminGid,
  updateFunction
) => {
  const urlPath = config.API_ENDPOINTS.PUT_ORDER_ADMIN_UPDATE_USER;
  postData.update_function = updateFunction;
  postData.order_admin_gId = orderAdminGid;

  return () => {
    return Promise.all([axios.put(urlPath, postData)])
      .then((resp) => {
        if (
          updateFunction ===
          "add-user-shipping-address-as-a-new-delivery-address"
        ) {
          let isError = false;
          if (resp[0].data) {
            if (resp[0].data.success) {
              if (resp[0].data.success !== true) {
                isError = true;
              }
            } else {
              isError = true;
            }
          } else {
            isError = true;
          }
          if (isError) {
            alert("Fel: Något hände och leveransadressen kunde inte sparas");
          }
        } else if (updateFunction === "save-user-info") {
          if (resp[0].data) {
            return true;
          }
        } else if (updateFunction === "accept-pending-user") {
          if (resp[0].data) {
            return true;
          }
        } else if (updateFunction === "create-new-main-customer") {
          if (resp[0].data) {
            return true;
          }
        } else if (updateFunction === "save-main-customer-edits") {
          if (resp[0].data) {
            return true;
          }
        }
      })
      .catch((error) => {
        console.log("error allWccd", error);
      });
  };
};

export const refreshUser = (user) => {
  return {
    type: REFRESH_USER,
    payload: {
      user,
    },
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
    payload: {},
  };
};
