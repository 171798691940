import { createStore, compose, applyMiddleware } from 'redux';
import persistState from 'redux-localstorage';

import thunk from 'redux-thunk';
import rootReducer from './reducers/';

const persistor = persistState();
const middleware = applyMiddleware(thunk);
const devTool = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f;

const enhancers = compose(middleware, persistor, devTool);

export default createStore(rootReducer, enhancers);
